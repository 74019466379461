/* styles.css */
.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .scroll-button {
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: 1px solid var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    transition: opacity 0.3s;
    padding: 15px;
  }
  
  .scroll-button:hover {
    background-color: var(--primary-color);
    border: 1px solid #fff;
  }

  .scroll-to-top i{
    color: var(--primary-color);
  }

  .scroll-button:hover i{
    color: #fff;
  }

  @media only screen and (max-width:1024px) {
    .scroll-button {
        padding: 10px;
      }
  }
  

  @media only screen and (max-width:999px) {
    .scroll-button {
        padding: 10px;
      }
  }
  

  @media only screen and (max-width:767px) {
    .scroll-button {
        padding: 10px;
      }
  }
.GrowingBuisiness {
    margin: 100px 0 0 0;
}

.GrowingBuisiness .content-box {
    margin: 50px 0 0 0;
}


.GrowingBuisiness .content-box .box h4 {
    font-size: 24px;
    font-family: 'GorditaBold';
    color: var(--black-color);
    margin: 20px 0 0 0;
}

.GrowingBuisiness .content-box .box p {
    font-size: 18px;
    color: #272727;
    margin: 30px 0 0 0;
}

.GrowingBuisiness .content-box .box a {
    text-decoration: none;
    color: #343540;
    font-size: 16px;
    padding: 0 5px 5px 0;
    font-family: 'GorditaMedium';
    position: relative;
}

.GrowingBuisiness .content-box .box a:after {
    width: 20%;
    height: 2px;
    background-color: var(--primary-color);
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 0.2s linear;
}

.GrowingBuisiness .content-box .box a:hover:after {
    width: 100%;
}


.GrowingBuisiness .content-box .box {
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    border: 1px solid var(--primary-color);
    border-radius: 36px;
    padding: 50px;
    background-color: var(--white-color);
    transform: scale(1);
    transition: all 0.3s linear;
    height: 100%;
}

.GrowingBuisiness .content-box .box .item-box {
    position: relative;
    height: 100%;
}


.GrowingBuisiness .content-box .box:hover {
    transform: scale(1.02);
}


.GrowingBuisiness .content-box .box .box-footer {
    text-align: end;
    margin: 20px 0 0 0;
    position: absolute;
    bottom: 0;
    right: 0;
}



/* Blue Box */



.GrowingBuisiness .content-box .blue-box h4 {
    font-size: 25px;
    font-family: 'GorditaBold';
    color: var(--white-color);
    margin: 20px 0 0 0;
}

.GrowingBuisiness .content-box .blue-box p {
    font-size: 18px;
    color: #ffffff;
    margin: 30px 0 30px 0;
}

.GrowingBuisiness .content-box .blue-box a {
    text-decoration: none;
    color: #ffffff;
    font-size: 16px;
    padding: 0 5px 5px 0;
    font-family: 'GorditaMedium';
    position: relative;
}

.GrowingBuisiness .content-box .blue-box a:after {
    width: 20%;
    height: 2px;
    background-color: var(--white-color);
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 0.2s linear;
}

.GrowingBuisiness .content-box .blue-box a:hover:after {
    width: 100%;
}


.GrowingBuisiness .content-box .blue-box {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 36px;
    padding: 50px;
    background-color: #4C64FD;
    transform: scale(1);
    transition: all 0.3s linear;
    height: 100%;

}

.GrowingBuisiness .content-box .blue-box .item-box {
    position: relative;
    height: 100%;
}




.GrowingBuisiness .content-box .blue-box:hover {
    transform: scale(1.02);
}


.GrowingBuisiness .content-box .blue-box .box-footer {
    text-align: end;
    margin: 20px 0 0 0;
    position: absolute;
    bottom: 0;
    right: 0;
}


@media only screen and (max-width:999px) {
.GrowingBuisiness {
    margin: 60px 0 0 0;
}
}

@media only screen and (max-width:767px) {
    .GrowingBuisiness {
        margin: 50px 0 0 0;
    }

    .GrowingBuisiness .content-box {
        margin: 30px 0 0 0;
    }

    .GrowingBuisiness .content-box .box {
        padding: 25px;
        margin: 0px 10px;
    }

    .GrowingBuisiness .content-box .blue-box {
        padding: 25px;
        margin: 0px 10px;
    }

    .GrowingBuisiness .content-box .box h4 {
        font-size: 20px;
        margin: 20px 0 0 0;
    }

    .GrowingBuisiness .content-box .box p {
        font-size: 14px;
        margin: 20px 0 46px 0;
    }


    .GrowingBuisiness .content-box .blue-box h4 {
        font-size: 20px;
        font-family: 'GorditaBold';
        color: var(--white-color);
        margin: 20px 0 0 0;
    }

    .GrowingBuisiness .content-box .blue-box p {
        font-size: 14px;
        margin: 20px 0 46px 0;
    }


}
.ResourceBanner .ResourceBanner-box {
    background: linear-gradient(to bottom, #4C64FD28 0%, #73737300 100%);
    border-radius: 40px 40px 0 0;
    padding: 75px;
}

.ResourceBanner .ResourceBanner-box .content-box {
    margin: 100px 0 0 0;
}

.ResourceBanner .ResourceBanner-box .content-box h4 {
    color: #2C2D2D;
    font-size: 24px;
    font-family: 'GorditaMedium';
    margin: 30px 0 0 0;
}

.ResourceBanner .ResourceBanner-box .content-box p {
    color: #2C2D2D;
    font-size: 18px;
    margin: 20px 0 0 0;

}

.ResourceBanner .ResourceBanner-box .content-box .card {
    background-color: #4c63fd20;
    text-align: center;
    padding: 25px;
    border-radius: 40px;
    border: none;
    height: 100%;
}

@media only screen and (max-width:999px) {
    .ResourceBanner .ResourceBanner-box {
        background: linear-gradient(to bottom, #4C64FD28 0%, #73737300 100%);
        border-radius: 40px 40px 0 0;
        padding: 50px 50px 0 50px;
    }

    .ResourceBanner .ResourceBanner-box .content-box .card {
        background-color: #4c63fd20;
        text-align: center;
        padding: 35px;
        border-radius: 40px;
        border: none;
    }

    .ResourceBanner .ResourceBanner-box .content-box {
        margin: 60px 0 0 0;
    }
}

@media only screen and (max-width:767px) {
    .ResourceBanner .ResourceBanner-box {
        padding: 40px 20px 0 20px;
    }

    .ResourceBanner .ResourceBanner-box .content-box {
        margin: 30px 0 0 0;
    }

    .ResourceBanner .ResourceBanner-box .content-box .card {
        padding: 25px;
    }

    .ResourceBanner .ResourceBanner-box .content-box p {
        font-size: 14px;
        margin: 10px 0 0 0;
    }

    .ResourceBanner .ResourceBanner-box .content-box h4 {
        font-size: 20px;
        margin: 20px 0 0 0;
    }
}



.ourMission{
    margin: 200px 0 0 0;
}

.ourMission .inner-subheading-box p{
    padding: 0 0 35px 0;
    color: #000000;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
}


.ourMission .left .img-box{
    position: relative;
    height: 50px;
}


.ourMission .left .img-box .icon{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

.ourMission .left .item .img-box .dot-bg{
    position: absolute;
    top: 0;
    left: -3px;
    transition: all 0.3s linear;
}

.ourMission .left .item:hover .img-box .dot-bg{
    position: absolute;
    top: 0;
    left: -35px;
}

.ourMission .left h5{
    font-size: 15px;
  font-family: 'GorditaMedium';
    color: #2F2F2F;
}

.ourMission .left .item p{
  font-family: 'GorditaMedium';
    font-size: 12px;
    color: #6A6A6A;
    line-height: 22px;
}

.ourMission .left .item {
    padding: 0 0 20px 0;
}

.ourMission .right{
    background-image: url('../../assets/images/about/mission.png');
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
}




@media only screen and (max-width:767px) {
    .ourMission {
        margin: 150px 0 0 0;
    }
}

.feature-banner {
    padding: 0 0 15% 0;
    overflow: hidden;

}

.feature-banner .bg-box {
    height: 65vh;
    background-color: var(--secondary-color);
    border-radius: 26px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    position: relative;
}


.feature-banner .bg-box .content-box {
    background-color: var(--white-color);
    position: absolute;
    left: 5%;
    bottom: -25%;
    width: 32%;
    padding: 35px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.feature-banner .bg-box .content-box h1 {
    font-size: 50px;
    color: var(--black-color);
    font-weight: bold;
    padding: 0 0 15px 0;
}

.feature-banner .bg-box .content-box p {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    padding: 0 0 10px 0;
}

.feature-banner .bg-box .content-box button {
  font-family: 'GorditaMedium';
}

.feature-banner .right{
    position: relative;
}

.feature-banner .right img{
    position: absolute;
    left: 0;
    top: 0;
}

.feature-banner .right .banner-item4{
    position: static;
}



.feature-banner .right  .banner-item1{
    animation: Leftright 3s linear infinite;
}

.feature-banner .right  .banner-item2{
    animation: Leftright 3s linear infinite;
}

.feature-banner .right  .banner-item3{
    animation: diagonalMove 3s linear infinite;
}


/* Feature One */

.featureOne{
    overflow: hidden;
}

.featureOne .content-box {
    background-color: var(--primary-color);
    padding: 100px 0;
    margin: 50px 0 0 0;
}


.featureOne .content-box .right {
    background-color: var(--white-color);
    height: 100%;
    padding: 30px 60px;
    border-radius: 20px;
    width: 100%;
}

.featureOne .content-box .right h2 {
    font-size: 48px;
    color: #2C2D2D;
    font-weight: bold;
}


.featureOne .content-box .right p {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    padding: 0 0 20px 0;
}

.featureOne .content-box .right .img-box{
    position: relative;
    height: 50px;
}

/* .featureOne .content-box .right .img-box .dot-bg{
    position: absolute;
    left: 0;
    top: 0;
    max-width: 100px;
    z-index: -1;
    background-color: red;
} */


.featureOne .content-box .right .img-box .icon{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

.featureOne .content-box .right .item .img-box .dot-bg{
    position: absolute;
    top: 0;
    left: -3px;
    transition: all 0.3s linear;
}

.featureOne .content-box .right .item:hover .img-box .dot-bg{
    position: absolute;
    top: 0;
    left: -35px;
}

.featureOne .content-box .right h5{
    font-size: 15px;
  font-family: 'GorditaMedium';
    color: #2F2F2F;
}

.featureOne .content-box .right .item p{
  font-family: 'GorditaMedium';
    font-size: 12px;
    color: #6A6A6A;
}

.featureOne .content-box .right .item {
    padding: 0 0 20px 0;
}

.featureOne .left{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.featureOne .left .img-box{
    position: relative;
}

.featureOne .left .img-box img{
    position: absolute;
    left: 0;
    top: 0;
}

.featureOne .left .img-box .shape1{
    animation: rightLeft 3s linear infinite;
}

.featureOne .left .img-box .shape2{
    animation: Leftright 6s linear infinite;
}





/* Feature Two */

.typeof-feature{
    margin: 100px 0 0 0;
    overflow: hidden;
}

.typeof-feature .content-box{
    padding: 150px 0 0 0;
}

.typeof-feature .content-box .img-box{
    position: relative;
}

.typeof-feature .content-box .featuretwo1{
    position: absolute;
    left: 0;
    top: 0;
}

.typeof-feature .content-box .featuretwo3{
    position: absolute;
    left: 0;
    top: 0;
}

.typeof-feature .content-box .right{
    height: 100%;
    padding: 0 0 0 90px
}

.typeof-feature .content-box .right p{
    font-size: 16px;
    color: #000000;
    font-weight: 400;
    line-height: 30px;

}

.typeof-feature .content-box .right li{
    font-size: 16px;
    color: #000000;
    font-weight: 400;
    line-height: 30px;
}

.typeof-feature .bottom {
    padding: 50px 0 0 0;
}

.typeof-feature .bottom h3{
    font-size: 32px;
    color: var(--black-color);
    font-weight: bold;
}

.typeof-feature .bottom h6{
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    color: var(--black-color);
}


/* Contact mnanagement */

.contact-management{
    padding: 100px 0 0 0;
}
.contact-management .right{
    position: relative;
}

.contact-management .right .img1{
    position: absolute;
    right: 0;
    top: 0;
}

.contact-management p{
    line-height: 30px;
    font-size: 16px;
    font-weight: 300;
    color: var(--black-color);
}

.sales-automation{
    padding: 50px 0 0 0;
}

/* Smart Contact */

.smartContactData{
    padding: 100px 0 0 0;
}

.smartContactData .right p{
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    line-height: 30px;
}

.smartContactData .right ul{
    list-style: none;
    padding: 0;
    padding: 15px 0 0 0;
}

.smartContactData .right li{
    display: flex;
    align-items: start;
    justify-content: start;
    padding: 0 0 15px 0;
}

.smartContactData .right li p{
    margin: 0;
    line-height: normal;
    padding: 0 0 0 15px;
}


.smartContactData .bottom {
    padding: 50px 0 0 0;
}

.smartContactData .bottom h3{
    font-size: 32px;
    color: var(--black-color);
    font-weight: bold;
}

.smartContactData .bottom h6{
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    color: var(--black-color);
}

.meassage-inbox{
    padding: 100px 0 100px 0;
}

.meassage-inbox  p{
    font-size: 16px;
    color: #000000;
    font-weight: 400;
    line-height: 30px;

}

.meassage-inbox  li{
    font-size: 16px;
    color: #000000;
    font-weight: 400;
    line-height: 30px;
}

@media screen and (min-width: 1200px) and (max-width: 1536px) {
    .feature-banner .bg-box .content-box h1 {
        font-size: 35px;
    }

    .feature-banner .bg-box .content-box p {
        font-size: 14px;
    }

.feature-banner .bg-box .content-box {
    background-color: var(--white-color);
    position: absolute;
    left: 1%;
    bottom: -33%;
    width: 30%;
    padding: 35px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
}

@media screen and (min-width: 1000px) and (max-width: 1024px) {

    .feature-banner .bg-box {
        height: 70vh;
    }
  

    .feature-banner .bg-box .content-box {
        background-color: var(--white-color);
        position: absolute;
        left: 50%;
        bottom: -30%;
        transform: translate(-50%, 0);
        width: 90%;
        padding: 20px;
    }

    .feature-banner .bg-box .banner-item {
        display: none;
    }

    .feature-banner {
        padding: 0 0 32% 0;
    }

    .feature-banner .bg-box .content-box h1 {
        font-size: 40px;
        padding: 0 0 10px 0;
    }
    
    .feature-banner .bg-box .banner-item3 {
        top: 43%;
    }

    .feature-banner .bg-box .content-box p {
        font-size: 14px;
        text-align: justify;
    }

    .featureOne .content-box .right h2 {
        font-size: 35px;
    }

    .featureOne .content-box .right {
        width: 100%;
        margin: 20px 0 0 0;
        padding: 50px 50px;

    }

    .featureOne .content-box .right p {
        font-size: 14px;
        padding: 0 0 10px 0;
    }

    .featureOne .content-box .right h5 {
        font-size: 14px;
    }

    .featureOne .content-box .right .item p {
        font-size: 10px;
    }

}

@media only screen and (max-width:999px) {
    .feature-banner .bg-box .content-box {
        background-color: var(--white-color);
        position: absolute;
        left: 50%;
        bottom: -30%;
        transform: translate(-50%, 0);
        width: 90%;
        padding: 20px;
    }

    .feature-banner .bg-box .banner-item {
        display: none;
    }

    .feature-banner {
        padding: 0 0 32% 0;
    }

    .feature-banner .bg-box .content-box h1 {
        font-size: 40px;
        padding: 0 0 10px 0;
    }
    
    .feature-banner .bg-box .banner-item3 {
        top: 43%;
    }

    .feature-banner .bg-box .content-box p {
        font-size: 14px;
        text-align: justify;
    }

    .featureOne .content-box .right h2 {
        font-size: 35px;
    }

    .featureOne .content-box .right {
        width: 100%;
        margin: 20px 0 0 0;
        padding: 50px 50px;

    }

    .featureOne .content-box .right p {
        font-size: 14px;
        padding: 0 0 10px 0;
    }

    .featureOne .content-box .right h5 {
        font-size: 14px;
    }

    .featureOne .content-box .right .item p {
        font-size: 10px;
    }

    .typeof-feature .bottom {
        padding: 15px 0 0 0;
    }


    .typeof-feature {
        margin: 60px 0 0 0;
    }

    .typeof-feature .content-box .right {
        padding: 10px 10px 10px 10px;
    }

    .typeof-feature .content-box {
        padding: 60px 0 0 0;
    }

    .typeof-feature .content-box .right {
        padding: 50px 0 0 0;
    }

    .typeof-feature .content-box .right p {
        font-size: 14px;
        line-height: 22px;
        text-align: justify;
    }

    .typeof-feature .content-box .right li {
        font-size: 14px;
        line-height: 22px;
    }

    .typeof-feature .bottom h6 {
        font-size: 14px;
        line-height: 22px;
    }

    .typeof-feature .bottom h3 {
        font-size: 20px;
    }

    .typeof-feature .content-box .featuretwo1 {
        position: absolute;
        left: 50%;
        transform: translate(-50%,0);
    }

    .typeof-feature .content-box .featuretwo3 {
        position: absolute;
        left: 50%;
        transform: translate(-50%,0);
    }

    .typeof-feature .content-box .img-box {
        text-align: center;
    }

    .contact-management {
        padding: 60px 0 0 0;
    }

    .contact-management p {
        line-height: 25px;
        font-size: 14px;
        text-align: justify;
    }

    .sales-automation {
        padding: 10px 0 0 0;
    }

    .smartContactData {
        padding: 60px 0 0 0;
    }

    .smartContactData .left{
        text-align: center;
    }

    .smartContactData .right p {
        font-size: 14px;
        line-height: 25px;
        text-align: justify;
    }

    .smartContactData .bottom h3 {
        font-size: 20px;
    }

    .smartContactData .bottom h6 {
        font-size: 14px;
        line-height: 22px;
    }

    .meassage-inbox {
        padding: 60px 0 60px 0;
    }

    .meassage-inbox p {
        font-size: 14px;
        text-align: justify;
        line-height: 25px;
    }

    .meassage-inbox li {
        font-size: 14px;
        line-height: 25px;
    }

    .meassage-inbox .right{
        text-align: center;
    }

    .ctaone {
        position: relative;
        margin: 0 0 60px 0;
    }
}


@media only screen and (max-width:767px) {

    .feature-banner .bg-box {
        height: 100%;
    }

    .feature-banner {
        padding: 0 0 40% 0;
    }

    .feature-banner .bg-box .banner-item3 {
        top: 0;
        right: 0;
        transform: translate(0, 0);
        padding: 17px;
    }

    .feature-banner .bg-box .banner-item {
        top: 10%;
        left: 5px;
    }

    .feature-banner .bg-box .content-box {
        left: 50%;
        transform: translate(-50%, 0px);
        bottom: -15%;
        width: 90%;
        padding: 20px;
    }

    .feature-banner .bg-box .content-box h1 {
        font-size: 30px;
        padding: 0 0 10px 0;
    }


    .featureOne .content-box .right {
        padding: 30px 30px;
        margin: 30px 0 0 0;
        width: 100%;
    }

    .featureOne .content-box .right h2 {
        font-size: 24px;
    }

    .featureOne .content-box .right p {
        font-size: 14px;
    }

    .featureOne .content-box .right .item {
        padding: 0;
    }



    .typeof-feature {
        margin: 50px 0 0 0;
    }

    .typeof-feature .content-box {
        padding: 40px 0 0 0;
    }

    .typeof-feature .content-box .right {
        padding: 50px 10px 0 10px;
    }

    .typeof-feature .content-box .right p {
        font-size: 14px;
        line-height: 25px;
        text-align: justify;
    }

    .typeof-feature .content-box .right li {
        font-size: 14px;
        line-height: 25px;
    }

    .typeof-feature .bottom h6 {
        font-size: 14px;
        line-height: 25px;
        text-align: justify;
    }

    .typeof-feature .bottom h3 {
        font-size: 16px;
        font-weight: bold;
    }

    .typeof-feature .bottom {
        padding: 10px 10px 0 10px;
    }

    .contact-management {
        padding: 50px 10px 0 10px;
    }

    .contact-management p {
        font-size: 14px;
        line-height: 25px;
        text-align: justify;
    }

    .sales-automation {
        padding: 30px 0 0 0;
    }

    .inner-subheading-box {
        padding: 30px 0 0 0;
    }

    .smartContactData {
        padding: 50px 10px 0 10px;
    }

    .smartContactData .right p {
        font-size: 14px;
        text-align: justify;
        line-height: 25px;
    }

    .smartContactData .bottom h3 {
        font-size: 14px;
    }

    .smartContactData .bottom h6 {
        font-size: 14px;
        text-align: justify;
        line-height: 25px;
    }
    
    .smartContactData .bottom {
        padding: 15px 0 0 0;
    }
    

    .meassage-inbox {
        padding: 50px 0 20px 0;
    }

    .meassage-inbox .inner-subheading-box {
        padding: 0;
    }

    .meassage-inbox p {
        font-size: 14px;
        line-height: 25px;
        text-align: justify;
    }

    .meassage-inbox li {
        font-size: 14px;
        line-height: 25px;
    }
}

.why-box {
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 100px 0 0 0;
    padding: 60px;
    background-color: var(--white-color);
}

.why-box .heading-box {
    text-align: center;
}

.why-box .heading-box h2 {
    font-size: 40px;
  font-family: 'GorditaMedium';
    color: var(--black-color);
}

.why-box .heading-box h6 {
  font-family: 'GorditaMedium';
    font-size: 16px;
    text-transform: uppercase;
    color: var(--primary-color);

}

.why-box .heading-box p {
    font-size: 16px;
    color: #585858;
    width: 59%;
    margin: auto;
}

.why-box .why-items {
    margin: 100px 0 0 0;
    display: flex;
    flex-wrap: nowrap;
    background-color: var(--secondary-color);
    padding: 20px;
    border-radius: 20px;
}

.why-box .why-items .why-item {
    display: flex;
    justify-content: center;
    align-items: start;
    width: 60%;
}

.why-box .why-items .why-item h2 {
    font-size: 120px;
    color: #0000001A;
    line-height: 80%;
    padding: 0 20px 0 20px;
  font-family: 'GorditaMedium';
}

.why-items .why-item .right {
    width: 80%;
}

.why-items .why-item .right h4 {
    font-size: 26px;
  font-family: 'GorditaBold';
    color: var(--black-color);
}

.why-items .why-item .right p {
    color: #627992;
    font-size: 14px;
    padding: 15px 0 15px 0;
}

.why-items .why-item .right a {
    background-color: var(--primary-color);
    color: var(--white-color);
    border: none;
    padding: 7px 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 14px;
}

@media only screen and (max-width:999px) {
    .why-items .why-item .right h4 {
        font-size: 20px;
    }

    .why-box .why-items {
        flex-wrap: wrap;
        margin: 50px 0 0 0;
    }

    .why-box {
        padding: 30px;
        margin: 50px 0 0 0;
    }
    .why-box .heading-box h2 {
        font-size: 30px;
    }

    .why-box .heading-box p {
        font-size: 14px;
        width: 90%;
    }

    .why-box .why-items .why-item {
        width: 100%;
        padding: 0 0 40px 0;
    }

    .why-items .why-item .right p {
        margin: 0;
        padding: 0px 0 15px 0;
    }

    .why-box .why-items .why-item h2 {
        font-size: 75px;
    }
}


@media only screen and (max-width:767px) {
    .why-box {
        padding: 25px 20px;
        margin: 50px 0 0 0;
    }

    .why-box .heading-box h6 {
        text-align: start;
        font-size: 14px;
        color: var(--primary-color);
    }

    .why-box .heading-box h2 {
        font-size: 20px;
        text-align: start;
    }

    .why-box .heading-box p {
        width: auto;
        text-align: start;
    }

    .why-box .why-items {
        margin: 50px 0 0 0;
        flex-wrap: wrap;
        gap: 40px;
    }

    .why-box .why-items .why-item {
        width: 100%;
    }

    .why-items .why-item .right h4 {
        font-size: 20px;
    }

    .why-box .why-items .why-item h2 {
        font-size: 40px;
    }

    .why-items .why-item .right p {
        margin: 0;
    }

    .why-box .why-items .why-item h2 {
        font-size: 96px;
        padding: 0 10px;
    }

}
.client{
    overflow: hidden;
}


.client h2 {
    color: #4A4A4A;
    font-size: 36px;
}

.client .heading-box {
    text-align: center;
    padding: 100px 0 0 0;

}

.client .items {
    margin: 50px 0 0 0;
    background-color: var(--secondary-color);
    border-radius: 20px;
    padding: 50px 100px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;
}


.client .clientSlider .card{
    background-color: transparent;
    border: none;
}

.client .clientSlider .card img{
    width: 150px;
    aspect-ratio: 3/1;
    object-fit: contain;
}
@media only screen and (max-width:1024px) {}

@media only screen and (max-width:999px) {
    .client .heading-box {
        padding: 50px 0 0 0;
    }

    .client h2 {
        color: #4A4A4A;
        font-size: 25px;
    }

    .client .items {
        padding: 40px;
        margin: 20px 0 0 0;
        gap: 10px;
    }
}

/* mobile screen */

@media only screen and (max-width:767px) {
    .client .items {
        padding: 25px 5px;
        gap: 10px 0;
        margin: 20px 0 0 0;
    }

    .client .items img {
        max-width: 100px;
    }

    .client h2 {
        color: #4A4A4A;
        font-size: 20px;
    }

    .banner .banner-text-box p {
        font-size: 16px;
        width: 80%;
    }

    .banner .banner-text-box h1 {
        font-size: 30px;
    }

    .client .heading-box {
        padding: 40px 0 0 0;
    }
}
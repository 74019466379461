.features {
    background-color: var(--primary-color);
    padding: 150px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px 0 0 0;
    overflow: hidden;
}

.feature-box {
    background-color: var(--white-color);
    padding: 40px 100px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.feature-box h2 {
    text-align: center;
    font-size: 40px;
  font-family: 'GorditaMedium';
    padding: 0 0 40px 0;
}

.feature-box .right .nav-item img {
    height: 100px;
    width: 100px;
}

.feature-box .right .nav-item .nav-link {
    height: 90px;
    width: 130px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);
    color: var(--primary-color);
   
}

.feature-box .right .nav-item .nav-link.active {
    background-color: var(--primary-color);
    color: var(--white-color);
    padding: 20px;
    border: 2px solid rgb(255, 255, 255);
    box-shadow: 0px 4px 20px 0px #00000030;
}



.feature-box .right .nav-item .nav-link.active svg path {
    fill: var(--white-color) !important;
    stroke: var(--white-color);
}

.feature-box .right .nav-item .nav-link p {
    margin: 0;
    padding: 8px 0 0 0;
    font-size: 16px;
}



.feature-box .right .content-box h5 {
    font-size: 30px;
    font-weight: bold;
    padding: 20px 0;
}




.feature-box .indicating-img {
    width: 100%;
    /* height: 100%; */
}

.feature-box .indicating-img-box-leads{
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* transition: all 1s ease-in-out; */

}


/* .feature-box .indicating-img-box-leads:hover .lead-img1,
.feature-box .indicating-img-box-leads:hover .lead-img7,
.feature-box .indicating-img-box-leads:hover .lead-img2{
    filter: brightness(0.3) 
}

.feature-box .indicating-img-box-leads:hover .lead-img3,
.feature-box .indicating-img-box-leads:hover .lead-img6{
    scale: 1.03;
} */

.feature-box .indicating-img-box-leads img{
    position: absolute;
    left: 0;
    top: 0;
}

.feature-box .indicating-img-box-leads .lead-img1{
    position: static;
}


.feature-box .indicating-img-box-leads .lead-img3{
    animation: downUp 2s linear infinite;
}

.feature-box .indicating-img-box-leads .lead-img1{
    animation: Leftright 2s linear infinite;
}


.feature-box .indicating-img-box-leads .lead-img6{
    animation: upDown  2s linear infinite;
}


.feature-box .indicating-img-box-leads svg{
    position: absolute;
    left: 0;
    top: 30px;
    z-index: 1;
    max-width: 100%;
    height: auto;
}

.feature-box .indicating-img-box-leads svg .line {
    animation: fill .3s infinite linear;
}


.feature-box .indicating-img-box-engagements{
    position: relative;
}

.feature-box .indicating-img-box-engagements .engage-img6{
    position: static
}


.feature-box .indicating-img-box-engagements .engage-img3{

    animation: upDown 2s linear infinite;
}

.feature-box .indicating-img-box-engagements .engage-img5{

    animation: upDown 2s linear infinite;
}

.feature-box .indicating-img-box-engagements .engage-img4{

    animation: rightLeft 2s linear infinite;
}

.feature-box .indicating-img-box-engagements img,.feature-box .indicating-img-box-engagements svg{
    position: absolute;
    top: 0;
    left: 0;
}


.feature-box .indicating-img-box-engagements svg .line {
    animation: fill .3s infinite linear;
}


.feature-box .indicating-img-box-customisation{
    position: relative;
}


.feature-box .indicating-img-box-customisation .custom-img3{
    animation: Leftright 3s infinite linear;
}

.feature-box .indicating-img-box-customisation .custom-img2{
    animation: upDown 3s infinite linear;
}

.feature-box .indicating-img-box-customisation .custom-img1{
    animation: upDown 3s infinite linear;
}

.feature-box .indicating-img-box-customisation .custom-img6{
    position: static;
}




.feature-box .indicating-img-box-customisation img,.feature-box .indicating-img-box-customisation svg{
    position: absolute;
    top: 0;
    left: 0;
}


.feature-box .indicating-img-box-customisation svg .line {
    animation: fill .3s infinite linear;
}



.feature-box .indicating-img-box-reports{
    position: relative;
}


.feature-box .indicating-img-box-reports .report-img3{
    position: static;
    
}

.feature-box .indicating-img-box-reports .report-img2{
    animation: diagonalMove 3s linear infinite;
}

.feature-box .indicating-img-box-reports .report-img6{
    animation: upDown 3s infinite linear;
}
.feature-box .indicating-img-box-reports .report-img1{
    animation: rightLeft 3s infinite linear;
}


.feature-box .indicating-img-box-reports img,
.feature-box .indicating-img-box-reports svg{
    position: absolute;
    top: 0;
    left: 0;
}



.feature-box .indicating-img-box-reports svg .line {
    animation: fill .3s infinite linear;
}






.feature-box .right{
    height: 100%;
}

.feature-box .nav{
    height: 100%;
}

.feature-box .nav-item{
    width: 50%;
    height: 50%;
    padding: 10px;
}


.feature-box .right .nav-item .nav-link.active {
    height: 100%;
    width: 100%;
    border-radius: 20px;
}

.feature-box .right .nav-item .nav-link {
    height: 70%;
    width: 70%;
    border-radius: 20px;
    border: 2px solid rgb(255, 255, 255);
    box-shadow: 0px 4px 20px 0px #00000030;
    transition: all 0.2s linear;
}

.feature-box .right .nav-item:hover .nav-link{
    height: 100%;
    width: 100%;
    background-color: var(--primary-color);
    color: var(--white-color);
}

.feature-box .right .nav-item:hover .nav-link svg path{
    fill: var(--white-color) !important;
    stroke: var(--white-color);
}

.feature-box .right .nav-item:hover .nav-link p{
    font-size: 20px;
}



@keyframes fill {
    0% {
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dashoffset: -1000;
    }
}





.feature-list {
    list-style: none;
    padding: 70px 0 0 0;

}

.feature-list li {
    display: flex;
    align-items: start;
    justify-content: start;
}

.feature-list li img {
    margin: 0 10px 0 0;
}

.feature-list li p {
    font-size: 16px;
    color: #2E4C6E;
    line-height: 26px;
}

.feature-list li p span{
    font-size: 14px;
}

@media only screen and (max-width:1400px) {
.feature-box .right .nav-item .nav-link p {
    margin: 0;
    padding: 8px 0 0 0;
    font-size: 14px;
}
}

@media only screen and (max-width:1199px) {
    .feature-box .nav {
        height: 370px;
    }

    .feature-list {
        padding: 0px 0 0 0;
    }
}

@media only screen and (max-width:1024px) {}

@media only screen and (max-width:999px) {
    .feature-list li p {
        font-size: 14px;
    }

    .feature-list li img {
        max-width: 20px;
    }

    .feature-box h2 {
        text-align: center;
        font-size: 30px;
    }

    .features {
        padding: 60px 0;
        margin: 50px 0 0 0;
    }

    .feature-box {
        padding: 50px 50px;
    }
}

/* mobile screen */

@media only screen and (max-width:767px) {
    .feature-box {
        padding: 25px 20px;
        border-radius: 20px;
    }

    .feature-box h2 {
        font-size: 25px;
    }

    .feature-box .nav-pills {
        text-align: center;
        display: flex;
        justify-content: start;
        align-items: start;
        gap: 0px;
    }

    .features {
        padding: 60px 0;
        margin: 50px 0 0 0;
    }

    .feature-list li img {
        max-width: 18px;
    }

    .feature-list li p {
        font-size: 14px;
        margin: 0 0 10px 0;
    }

    .feature-box .right .nav-item .nav-link p {
        margin: 0;
        padding: 8px 0 0 0;
        font-size: 14px;
    }

    .feature-box .right .nav-item .nav-link {
        height: 80%;
        width: 80%;
    }

    .feature-list {
        list-style: none;
        padding: 30px 0 0 0;
    }

    .feature-box .nav-item {
        width: 50%;
        height: 50%;
        padding: 2px;
    }

    .feature-box .nav {
        height: 300px;
        margin: 28px 0 0 0;
    }


}
.testimonial2{
    padding: 100px 0 0 0;
}

.testimonial2 .swiper {
    padding: 60px 0 100px 0;
}

.testimonial2 .heading-box{
    text-align: center;
    padding: 0 0 50px 0;
}

.testimonial2 .heading-box h5{
    color: #475467;
    font-size: 20px;
    font-weight: 400;
}

.testimonial2 .company-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.testimonial2 .company-box .box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.testimonial2 .auther{
    display: flex;
    flex-direction: column;
}

.testimonial2 .auther h6{
    font-size: 16px;
    color: #072130;
font-family: 'GorditaBold';
}


.testimonial2 .author-img{
    max-width: 60px;
    border-radius: 30px;
}


.testimonial2 .auther p{
    font-size: 14px;
    font-weight: 400;
}

.testimonial2 .card{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: none;
    border-radius: 24px;
    padding: 10px;
    height: 100%;
}

.testimonial2 .card-text{
    color: #072130;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}


.testimonial2 .swiper-slide {
    height: auto;
}

.testimonial2 .card-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    background-color: var(--white-color);
    border-radius: 0 0 24px 24px;
}

.testimonial2 .company-box .box h5{
    font-size: 25px;
    color: var(--black-color);
    padding: 20px 0;

}

.testimonial2 .company-box .box h5 span{
  font-family: 'GorditaBold';
}


@media only screen and (max-width:1024px) {
    
}

@media only screen and (max-width:999px) {
    
}

@media only screen and (max-width:767px) {
    .testimonial2 {
        padding: 50px 10px 0px 10px;
    }

    .testimonial2 .heading-box {
        padding: 0 0 20px 0;
    }

    .testimonial2 .heading-box h5 {
        font-size: 16px;
    }

    .testimonial2 .company-logo{
        max-width: 125px;
    }

    .testimonial2 .company-box .box h5{
        font-size: 18px;
        color: var(--black-color);
    }

    .testimonial2 .company-box .box h5 span{
      font-family: 'GorditaBold';
    }
}
.about-banner {
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 40px 0;
}


.about-banner h2{
    color: var(--white-color);
    font-size: 50px;
    font-family: 'GorditaMedium';
    line-height: 40px;
    padding: 0 0 25px 0;
}




.about-banner .right {
    background-color: var(--white-color);
    height: 100%;
    padding: 30px 60px;
    border-radius: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.about-banner .right h2 {
    font-size: 45px;
    color: #2C2D2D;
    font-weight: bold;
}


.about-banner .right p {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    padding: 0 0 20px 0;
}

.about-banner .right .img-box {
    position: relative;
    height: 50px;
}


.about-banner .right .img-box .icon {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

.about-banner .right .item .img-box .dot-bg {
    position: absolute;
    top: 0;
    left: -3px;
    transition: all 0.3s linear;
}

.about-banner .right .item:hover .img-box .dot-bg {
    position: absolute;
    top: 0;
    left: -35px;
}

.about-banner .right h5 {
    font-size: 15px;
  font-family: 'GorditaMedium';
    color: #2F2F2F;
}

.about-banner .right .item p {
  font-family: 'GorditaMedium';
    font-size: 12px;
    color: #6A6A6A;
}

.about-banner .right .item {
    padding: 0 0 20px 0;
}

.about-banner .left {
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
}

.about-banner .left h1 {
    color: var(--white-color);
    font-size: 50px;
  font-family: 'GorditaMedium';
    line-height: 40px;
    padding: 0 0 25px 0;
}

.about-banner .left p {
    font-size: 16px;
    line-height: 24px;
    color: var(--white-color);
    font-weight: 400;
    padding: 0 0 50px 0;
}
.about-banner .left p a {

    color: var(--white-color);

}


@media only screen and (max-width:767px) {

    .about-banner .left p {
        margin: auto;
    }

    .about-banner .left h1 {
        font-size: 45px;
        padding: 0 0 10px 0;
    }

    .about-banner .right h2 {
        font-size: 30px;
    }

    

}
.counter{
    background-color: #4C64FD;
    margin: 100px 0 0 0;
}

.counters{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 60px 0;
    text-align: center;
}

.counters .counter-box h3{
    font-size: 32px;
  font-family: 'GorditaMedium';
    color: var(--white-color);
    margin: 0;
}

.counters .counter-box p{
    font-size: 14px;
    color: #E8E8E8;
    font-weight: 400;
    margin: 10px auto 0 auto;
    width: 80%;
    text-align: center;


}

@media only screen and (max-width:767px) {
.counter {
    background-color: #4C64FD;
    margin: 50px 0 0 0;
}

.counters {
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
}

.counters .counter-box{
    text-align: center;
}


}
.WhyZimpleCrmBanner {
  padding: 100px 0;
  overflow: hidden;
}


.WhyZimpleCrmBanner h1 {
  font-size: 50px;
  font-family: 'GorditaBold';
  color: #170F49;
  margin: 0 0 15px 0;
}

.WhyZimpleCrmBanner h5 {
  font-size: 22px;
  font-weight: 400;
  color: #170F4980;
  margin: 0 0 20px 0;
}

.WhyZimpleCrmBanner p {
  color: #170F49;
  font-size: 17px;
  font-family: 'GorditaRegular';
  line-height: 30px;
  margin: 0 0 30px 0;
}


.WhyZimpleCrmBanner .left {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.WhyZimpleCrmBanner .right {
  position: relative;
}

.WhyZimpleCrmBanner .right .button-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}




.button {
  display: inline-block;
  position: relative;
}

.button.is-play {
  background-color: #00000009c;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  ;
}

.button.is-play .button-outer-circle {
  background: rgb(0, 0, 0);
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.button.is-play .button-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  transform: translate(-50%, -50%);
}

.button.is-play .button-icon .triangle {
  -webkit-animation: fadeIn 7s ease;
  animation: fadeIn 7s ease;
}

.button.is-play .button-icon .path {
  stroke-dasharray: 90;
  stroke-dashoffset: 0;
  -webkit-animation: triangleStroke 3s;
  animation: triangleStroke 3s;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
}

.has-scale-animation {
  -webkit-animation: smallScale 3s infinite;
  animation: smallScale 3s infinite;
}

.has-delay-short {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes triangleStroke {
  from {
    stroke-dashoffset: 90;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes triangleStroke {
  from {
    stroke-dashoffset: 90;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes smallScale {
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes smallScale {
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(1.5);
    opacity: 0;
  }
}





@media only screen and (max-width:1537px) {
  .WhyZimpleCrmBanner p {
    font-size: 16px;
  }

  .WhyZimpleCrmBanner h5 {
    font-size: 18px;
  }
}





@media only screen and (max-width:1024px) {
  .WhyZimpleCrmBanner .right {
    text-align: center;
  }
}


@media only screen and (max-width:999px) {
  .WhyZimpleCrmBanner {
    padding: 40px 0;
  }

  .WhyZimpleCrmBanner h1 {
    font-size: 50px;
    margin: 0 0 10px 0;
  }

  .WhyZimpleCrmBanner p {
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 10px 0;
  }


  .WhyZimpleCrmBanner .right {
    text-align: center;
  }

  .WhyZimpleCrmBanner h5 {
    font-size: 16px;
    margin: 0 0 15px 0;
  }


}



@media only screen and (max-width:767px) {
  .WhyZimpleCrmBanner {
    padding: 10px 0;
  }

  .WhyZimpleCrmBanner h1 {
    font-size: 30px;
    margin: 0 0 18px 0;
  }

  .WhyZimpleCrmBanner h5 {
    font-size: 16px;
    margin: 0 0 15px 0;
  }

  .WhyZimpleCrmBanner p {
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 15px 0;
  }

  .WhyZimpleCrmBanner .right {
    text-align: center;
  }
}
.borderd-btn{
    background-color: var(--white-color);
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
}

.touchPoint{
    margin: 100px 0 0 0;
}


.touchPoint .content-box p{
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    line-height: 30px;
}

.touchPoint  .right{
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.touchPoint  .right .bg-blue{
    position: absolute;
    right: 0;
    top: 0;
}

.touchPoint  .right ul{
    list-style: none;
    padding: 15px 0 0 0;
    margin: 0 0 40px 0;
}

.touchPoint .right ul li{
    display: flex;
    align-items: start;
    justify-content: start;
    padding: 0 0 15px 0;
}

.touchPoint .right ul li p{
    
    margin: 0;
    line-height: normal;
    padding: 0 0 0 15px;
}

.touchPoint  .left{
    position: relative;
}

.touchPoint  .left img{
    position: absolute;
    left: 0;
    top: 0;
}

.touchPoint  .left .touchPointImg0{
    position: static;
}

/* .matching-line-path{
    animation: animateLine 100s infinite linear;
}


@keyframes animateLine {
    0% {
        stroke-dashoffset: 0;
    }
    100% {
        stroke-dashoffset: -1000;
    }
  }
 */

 @media only screen and (max-width:767px) {
 .touchPoint {
    margin: 50px 10px 0 10px;
}

.touchPoint .right p{
    font-size: 14px;
}

.touchPoint .right ul {
    margin: 0 0 10px 0;
}
 }






nav {
    background-color: var(--white-color);
    margin: 20px 0;
}

nav .nav-item {
    padding: 0 20px 0 0;
}

nav .nav-link {
    color: #374557;
    font-size: 16px;
    font-family: 'GorditaMedium';
}

nav .login-btn {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    font-size: 16px;
    border-radius: 10px;
    padding: 8px 20px;
    font-family: 'GorditaMedium';
}

nav .form-select {
    border: none;
}

.hamburger {
    max-width: 35px;
}

.navbar-toggler {
    border: none;
}

.navbar-toggler:focus {
    box-shadow: none;
    outline: none;
    border: none;
}

.preloader {
    background-color: var(--secondary-color);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    width: 32px;
    height: 32px;
    position: relative;
    border-radius: 50%;
    color: var(--primary-color);
    animation: fill 1s ease-in infinite alternate;
}

.loader::before,
.loader::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    left: 48px;
    top: 0;
    animation: fill 0.9s ease-in infinite alternate;
}

.loader::after {
    left: auto;
    right: 48px;
    animation-duration: 1.1s;
}

@keyframes fill {
    0% {
        box-shadow: 0 0 0 2px inset
    }

    100% {
        box-shadow: 0 0 0 10px inset
    }
}

/* .gtranslate_wrapper{
    display: none;
} */


.nav-item.active .nav-link {
    color: #4d64fd;
    /* Change to your desired active link color */
}


.navbar .navbar-brand img {
    width: 200px;
}
.pricing-package {
    padding: 100px 0 0 0;
    background-image: url('../../assets/images/pricing/bg2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.pricing-package .content-box{
    padding: 80px 200px 0 200px;
}

.pricing-package .content-box .item {
    display: flex;
    justify-content: start;
    align-items: start;
}

.pricing-package .content-box .item h5 {
    color: #2C2D2D;
    font-size: 18px;
  font-family: 'GorditaMedium';
    margin: 0;
    padding: 0 0 10px 0;
}

.pricing-package .content-box .item p {
    font-size: 14px;
    color: #2C2D2D;
    font-weight: 400;
    margin: 0;
    line-height: 24px;
}


.pricing-package .content-box .item .content {
    padding: 0 0 0 25px;
}

@media screen and (min-width: 1000px) and (max-width: 1400px) {
    .pricing-package .content-box {
        padding: 80px 10px 0 10px;
    }

}

@media only screen and (max-width:1537px) {
    .pricing-package .content-box {
        padding: 80px 100px 0 100px;
    }
}


@media only screen and (max-width:999px) {

    .pricing-package .content-box {
        padding: 80px 10px 0 10px;
    }

    .pricing-package {
        padding: 60px 0 0 0;
    }
}

@media only screen and (max-width:767px) {

    .pricing-package {
        padding: 50px 0 0 0;
    }

    .pricing-package .content-box {
           padding: 40px 10px 0 10px;
    }

    .pricing-package .content-box .item h5 {
        font-size: 16px;
        padding: 0 0 6px 0;
    }

    .pricing-package .content-box .item p {
        font-size: 12px;
        line-height: 18px;
    }
}


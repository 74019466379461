.employee-testimonial {
    padding: 100px 0 0 0;
    overflow: hidden;
}

.employee-testimonial .swiper {
    padding: 80px 0 0 0;
}

.employee-testimonial .card .card-title {
    font-size: 16px;
    font-weight: bold;
    color: var(--black-color);
}

.employee-testimonial .card .img-box img {
    filter: grayscale(100%);
    transition: all 0.3s linear;
}

.employee-testimonial .card:hover .img-box img {
    filter: grayscale(0);
}

.employee-testimonial .card {
    background-color: #F6F6FA;
    border: none;
    height: 100%;
}

.employee-testimonial .card .card-footer {
    border: none;
    background-color: transparent;
}

.employee-testimonial .swiper-slide {
    height: auto;
}

.employee-testimonial .card .card-footer h6 {
    font-size: 12px;
font-family: 'GorditaBold';
    color: #202244;
    margin: 0;
    padding: 0 0 5px 0;
}

.employee-testimonial .card .card-footer p {
    font-size: 10px;
    color: #313444;
    font-weight: 400;
}

.employee-testimonial .card .card-body {
    position: relative;
    padding: 40px 20px 20px 20px;
}

.employee-testimonial .card .quote {
    position: absolute;
    right: 20px;
    top: 20px;
    opacity: 0.2;

}


@media only screen and (max-width:767px) {
    .employee-testimonial {
        padding: 50px 0 0 0;
    }

    .employee-testimonial .swiper {
        padding: 40px 0 0 0;
    }
}
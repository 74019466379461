.Productivity {
    /* height: 100vh; */
    /* background-color: var(--secondary-color); */
    margin: 70px 0 0 0;
    padding: 30px 30px 0 30px;
    overflow: hidden;
}

.Productivity .content-box {
    text-align: center;
    padding: 30px 80px 20px 80px;
    border-radius: 20px;
    background-color: var(--white-color);
    width: fit-content;
    margin: 50px auto;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.Productivity .content-box h2 {
    color: var(--black-color);
    font-family: 'GorditaMedium';
    font-size: 55px;
}

.Productivity .content-box p {
    color: #757575;
    font-size: 18px;
    font-family: 'GorditaRegular';
    width: 48%;
    margin: auto;
    padding: 20px 0 15px 0;
}

.Productivity .content-box a {
    color: var(--third-color);
    font-family: 'GorditaMedium';
    font-size: 18px;
}

.Productivity .image-box {
    position: relative;
}

.Productivity .image-box .shape1{
    position: static;
}



.Productivity .image-box .shape3{
    animation: upDown 3s infinite linear;
}

.Productivity .image-box .shape5{
    animation: rightLeft 3s infinite linear;
}


.Productivity .image-box img ,.Productivity .image-box svg{
    position: absolute;
    left: 0;
    top: 0;
}




/* .Productivity .image-box .animeRocket{
    background-color: red;
} */






@media only screen and (max-width:1024px) {}


/* mobile screen */

@media only screen and (max-width:999px) {
    .Productivity {
        padding: 30px 30px 0 30px;
    }

    .Productivity .content-box {
        width: fit-content;
    }

    .Productivity .content-box h2 {
        font-size: 30px;
    }

    .Productivity .content-box p {
        font-size: 16px;
        width: 90%;
    }
}


@media only screen and (max-width:767px) {
    .Productivity {
        margin: 50px 0 0 0;
    }

    .Productivity .content-box {
        width: auto;
    }

    .Productivity .content-box {
        padding: 24px 20px;
        margin: 0 25px 20px 25px;
    }

    .Productivity .content-box h2 {
        font-size: 25px;
    }

    .Productivity .content-box p {
        font-size: 16px;
        width: auto;
    }

    .Productivity .content-box a {
        font-size: 16px;
    }
}
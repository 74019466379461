.market {
    margin: 100px 0 0 0;
    overflow: hidden;
}

.market .left {
    height: 100%;
    padding: 30px 0;
}

.market .left p {
    font-size: 18px;
    color: var(--black-color);
    margin: 0 0 30px 0;
}

.market .right p {
    font-size: 16px;
    color: var(--black-color);
    margin: 0 0 30px 0;
}

.market .left h6 {
    font-size: 18px;
    font-family: 'GorditaMedium';
    color: var(--black-color);
}

.market .left .img-section {
    position: relative;
    margin: 30px 0 0 0;
}

.market .left .img-section img {
    position: absolute;
    left: 0;
    top: 0;
}

.market .left .inner-subheading-box2 {
    margin: 100px 0 0 0;
}


.market .img-section .market-left-img9 {
    position: static !important;
}


.market .right {
    position: relative;
    height: 100%;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.market .right .blue-shape {
    position: absolute;
    left: 0;
    bottom: 0;
}

.market .right .img-section {
    position: relative;
    margin: 30px 0 0 0;
}

.market .right .img-section .right-img1 {
    position: static;
}

.market .right .img-section img {
    position: absolute;
    right: 0;
    top: 0;
}

@media only screen and (max-width:999px) {
    .market {
        margin: 60px 0 0 0;
        overflow: hidden;
    }
.market .left .inner-subheading-box2 {
    margin: 50px 0 0 0;
}
}

@media only screen and (max-width:767px) {
    .market {
        margin: 50px 10px 0 10px;
        overflow: hidden;
    }

    .market .left h6 {
        font-size: 16px;
    }

    .market .inner-subheading-box {
        padding: 0px 0 0 0;
        margin: 50px 0 0 0 !important;
    }

    .market .left p {
        font-size: 14px;
    }

    .market .right p {
        font-size: 14px;
    }


}
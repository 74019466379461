.nextLevel {
    margin: 100px 0 0 0;
}

.nextLevel .card {
    border: 0;
}

.nextLevel .card h5 {
  font-family: 'GorditaMedium';
    font-size: 24px;
    color: #170F49;
    margin: 10px 0 0 0;
}

.nextLevel .card p {
    font-size: 16px;
    color: #0000000;
  font-family: 'GorditaRegular';
    margin: 10px 0 0 0;
}

@media only screen and (max-width:999px) {
    .nextLevel .card p {
        font-size: 16px;
    }
}

@media only screen and (max-width:767px) {
    .nextLevel .card h5 {
        font-size: 18px;
        color: #170F49;
        margin: 10px 0 0 0;
    }

    .nextLevel .card p {
        font-size: 14px;
    }
}
.brand{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 100px 0 100px 0;
    overflow: hidden;
}

.brand img{
    filter: contrast(0.1);
}

@media only screen and (max-width:999px) {
    .brand {
        flex-wrap: wrap;
        padding: 60px 0 60px 0;
    }
}


@media only screen and (max-width:767px) {
    .brand {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        padding: 50px 0;
    
    }

    .brand img {
        filter: contrast(0.1);
        margin: 10px;
    }
}


.banner {
    background-image: url('../../assets/images/home/banner-bg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px 20px 0 0;
    padding: 0 10px;

}

.banner .banner-text-box {
    text-align: center;
    padding: 60px 0 0 0;

}

.banner .banner-text-box h1 {
    font-size: 55px;
    font-family: 'GorditaBold';
    color: var(--black-color);
    padding: 0 0 20px 0;
}

.banner .banner-text-box p {
    color: var(--black-color);
    font-size: 16px;
    width: 80%;
    margin: auto;
    padding: 0 0 20px 0;
}

.banner .banner-text-box a {
    color: var(--black-color);
}

.banner .banner-text-box a.button {
    background-color: var(--primary-color);
    color: var(--white-color);
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: all 0.3s ease-in-out;
}

.banner .banner-text-box button:hover{
    background-color: #3953ce;
}

@media only screen and (max-width:1537px) {

    .banner .banner-text-box h1 {
        font-size: 50px;
    }
}

@media only screen and (max-width:999px) {

    .banner {
        padding: 60px 0;
    }

    .banner .banner-text-box h1 {
        font-size: 40px;
    }

    .banner .banner-text-box p {
        width: 90%;
        font-size: 16px;
    }

    .banner .banner-text-box {
        padding: 0 10px;
    }

}

@media only screen and (max-width:767px) {
    .banner .banner-text-box button {
        padding: 8px 16px;
        font-size: 12px;
        border-radius: 6px;
    }
}
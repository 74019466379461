.ctaone {
    position: relative;
    margin: 0 0 100px 0;
    overflow: hidden;
}

.ctaone .content {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0px, -50%);
    padding: 0 90px;
}

.ctaone .content h2 {
    color: var(--white-color);
    font-size: 80px;
    font-family: 'GorditaBold';
    margin: 0;
}

.ctaone .content p {
    color: var(--white-color);
    font-size: 18px;
    font-family: 'GorditaMedium';
    padding: 0 0 20px 0;
}

.ctaone .content a {
    color: var(--black-color);
    font-size: 20px;
    padding: 15px;
    background-color: var(--white-color);
    border: none;
    border-radius: 8px;
    font-family: 'GorditaMedium';
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

/* Cta Two */


.ctatwo {
    position: relative;
    margin: 100px 0 100px 0;
    overflow: hidden;
}

.ctatwo .content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0 90px;
    text-align: center;
}

.ctatwo .content h2 {
    color: var(--white-color);
    font-size: 48px;
    font-family: 'GorditaBold';
    margin: 0;
    padding: 0 0 10px 0;
}

.ctatwo .content p {
    color: var(--white-color);
    font-size: 18px;
    font-family: 'GorditaMedium';
    padding: 0 0 20px 0;
}

.ctatwo .content a {
    color: var(--black-color);
    font-size: 20px;
    padding: 10px;
    background-color: var(--white-color);
    border: none;
    border-radius: 8px;
    font-family: 'GorditaMedium';
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}


.ctaone .sm {
    display: none;
}

.ctatwo .sm {
    display: none;
}


@media screen and (max-width: 1537px) {
    .ctaone .content h2 {
        font-size: 55px;
    }

    .add-on-plans .content-box .package-box {
        width: 90%;
    }


    .ctatwo .content h2 {
        font-size: 40px;
    }

    .ctatwo .content p {
        font-size: 16px;
    }

    .ctatwo .content a {
        font-size: 16px;
    }


}



@media screen and (min-width: 1000px) and (max-width: 1200px) {
    .ctaone .content h2 {
        font-size: 30px;
    }

    .ctaone .content a {
        font-size: 14px;
        padding: 5px 13px;
    }

    .ctaone .content p {
        font-size: 14px;
        padding: 0;
    }

    .ctaone .content {
        padding: 0px 45px;
    }


}


@media only screen and (max-width:999px) {

    .ctaone .content h2 {
        font-size: 30px;
    }

    .ctaone .content a {
        font-size: 14px;
        padding: 5px 13px;
    }

    .ctaone .content p {
        font-size: 14px;
        padding: 0;
    }

    .ctaone .content {
        padding: 0px 45px;
    }

    .ctatwo .content h2 {
        font-size: 25px;
    }

    .ctatwo .content {
        padding: 0;
    }

    .ctatwo .content button {
        padding: 5px 13px;
        font-size: 14px;
    }

    .ctatwo .content p {
        color: var(--white-color);
        font-size: 14px;
        padding: 0 0 0px 0;
    }
}

@media only screen and (max-width:767px) {

    .ctaone {
        margin: 0;
    }

    .ctaone .sm {
        display: block;
    }

    .ctaone .lg {
        display: none;
    }

    .ctaone .content {
        padding: 0 27px;
        width: 90%;
    }

    .ctaone .content h2 {
        font-size: 30px;
    }

    .ctaone .content p {
        font-size: 14px;
        padding: 0;
    }

    .ctaone .content a {
        padding: 8px 16px;
        font-size: 12px;
        border-radius: 6px;
    }

    .ctatwo .lg {
        display: none;
    }

    .ctatwo .sm {
        display: block;
    }


    .ctatwo .content h2 {
        color: var(--white-color);
        font-size: 30px;
        font-family: 'GorditaBold';
        margin: 0;
        padding: 0 0 10px 0;
    }

    .ctatwo .content {
        padding: 0;
        width: 90%;
    }

    .ctatwo .content button {
        padding: 8px 16px;
        font-size: 12px;
        border-radius: 6px;
    }

    .ctatwo .content p {
        font-size: 14px;
        padding: 0;
    }

    .ctatwo {
        position: relative;
        margin: 50px 0 50px 0;
        overflow: hidden;
    }


}
@font-face {
  font-family: 'GorditaBold';
  src: url('../src/assets/font/GorditaBold.woff');
}

@font-face {
  font-family: 'GorditaMedium';
  src: url('../src/assets/font/GorditaMedium.woff');
}

@font-face {
  font-family: 'GorditaRegular';
  src: url('../src/assets/font/GorditaRegular.woff');
}





* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'GorditaRegular';
}

:root {
  --white-color: #fff;
  --black-color: #000;
  --primary-color: #556EE5;
  --secondary-color: #EEF3FF;
  --third-color: #00E096;
}

@media (min-width: 1537px) {
  .cust-container {
    max-width: 1700px !important;
  }
}


@media (min-width: 1440px) and (max-width: 1536px) {
  .cust-container {
    max-width: 1320px !important;

  }
}


a {
  text-decoration: none !important;
}

.btn-primary {
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-weight: 600;

  transition: all 0.3s ease-in-out;
}

.btn-primary:hover{
  background-color: #3953ce;
}


.btn-primary-outline {
  background-color: var(--white-color);
  color:  var(--primary-color);
  border: 1px solid var(--primary-color);
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-weight: 600;
}




/* @media (min-width: 1920px) {
  .container {
    max-width: 1740px !important;
  }
} */


.inner-heading-box,
.inner-heading-box-white {
  text-align: center;
}

.inner-heading-box h2 {
  color: #170F49;
  font-size: 50px;
  font-family: 'GorditaBold';
  font-weight: bold;
}

.inner-heading-box p {
  color: #170F49;
  font-family: 'GorditaRegular';
  font-size: 18px;
  width: 44%;
  margin: auto;
  font-weight: 400;
  padding: 5px 0 0 0;
}


.inner-heading-box {
  text-align: center;
}

.inner-heading-box-white h2 {
  color: var(--white-color);
  font-size: 50px;
  font-family: 'GorditaBold';
}

.inner-heading-box-white p {
  color: var(--white-color);
  font-family: 'GorditaRegular';
  font-size: 18px;
  width: 44%;
  margin: auto;
  font-weight: 400;
  padding: 5px 0 0 0;
}



.inner-heading-box h6 {
  text-transform: uppercase;
  color: #170F49;
  font-size: 18px;
  font-family: 'GorditaBold';
}


.inner-subheading-box {
  text-align: start;
}

.inner-subheading-box h5 {
  color: #556EE5;
  font-family: 'GorditaBold';
  font-size: 16px;
}

.inner-subheading-box h3 {
  font-size: 36px;
  color: var(--black-color);
  font-weight: 600;
  padding: 0 0 20px 0;
  font-family: 'GorditaMedium';
}

@media only screen and (max-width:1440px) {
  .inner-heading-box h2 {
    font-size: 40px;
  }

  .inner-heading-box p {
    width: 50%;
  }
}

@media only screen and (max-width:1024px) {
  .inner-heading-box h2 {
    font-size: 40px;
  }

  .inner-heading-box p {
    width: 90%;
  }
}

@media only screen and (max-width:999px) {
  .inner-heading-box h2 {

    font-size: 35px;
  }

  .inner-heading-box p {
    width: 90%;
    font-size: 14px;
  }

  .btn-primary {
    padding: 5px 9px;
  }

  .inner-subheading-box h3 {
    font-size: 22px;
    padding: 0 0 10px 0;
  }

}


@media only screen and (max-width:767px) {

  .inner-subheading-box h3 {
    font-size: 20px;
    color: var(--black-color);
  font-family: 'GorditaMedium';
    padding: 0 0 5px 0;
  }

  .btn-primary {
    padding: 8px 16px;
    font-size: 12px;
    border-radius: 6px;
  }


  .btn-primary-outline {
    padding: 8px 16px;
    font-size: 12px;
    border-radius: 6px;
}

  .inner-heading-box h2 {
    font-size: 30px;
    margin: 0px;
  }

  .inner-heading-box h6 {
    text-transform: uppercase;
    color: #170F49;
    font-size: 18px;
  }

  .inner-heading-box p {
    color: #170F49;
    font-family: 'GorditaRegular';
    font-size: 14px;
    width: 90%;
    margin: auto;
    font-weight: 400;
    padding: 5px 0 0 0;
  }

  .inner-heading-box-white h2 {
    font-size: 30px;
  }


  .inner-heading-box-white p {
    color: var(--white-color);
    font-family: 'GorditaRegular';
    font-size: 14px;
    width: 90%;
    margin: auto;
    font-weight: 400;
    padding: 5px 0 0 0;
  }



}



@keyframes downUp {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes upDown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(15px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes diagonalMove {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(20px, -20px);
  }

  100% {
    transform: translate(0, 0);
  }
}




@keyframes rightLeft {
  0% {
    transform: translatex(0);
  }

  50% {
    transform: translatex(15px);
  }

  100% {
    transform: translatex(0);
  }
}

@keyframes Leftright {
  0% {
    transform: translatex(0);
  }

  50% {
    transform: translatex(-15px);
  }

  100% {
    transform: translatex(0);
  }
}


@keyframes roatation {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes zoom {
  0% {
    transform: scale(1.02);
  }

  50% {
    transform: scale(1.04);
  }

  100% {
    transform: scale(1.02);
  }
}
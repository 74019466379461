.ourGoal{
    padding: 100px 0 0 0;
}

.ourGoal .right .heading-para{
    color: #000000;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
}
.ourGoal .right ul{
    list-style: none;
}

.ourGoal .right li{
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0 0 20px 0;
}

.ourGoal .right ul p{
    margin: 0;
    line-height: normal;
    padding: 0 0 0 15px;
    color: #000000;
    font-size: 16px;
}

.ourGoal .inner-heading-box{
    padding: 0 0 50px 0;
}

.ourGoal .ourGoal-left{
    height: 100%;
    background-image: url('../../assets/images/about/ourGoal.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
}

@media only screen and (max-width:767px) {
    .ourGoal {
        padding: 50px 0 0 0;
    }
    
}

.support {
    margin: 200px 0 0 0;
}

.support .inner-subheading-box h3 {
    color: #170F49;
}



.support .inner-subheading-box p {
    padding: 0 0 35px 0;
    color: #0000000;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
}


.support .left .img-box {
    position: relative;
    height: 50px;
}


.support .left .img-box .icon {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

.support .left .item .img-box .dot-bg {
    position: absolute;
    top: 0;
    left: -3px;
    transition: all 0.3s linear;
}

.support .left .item:hover .img-box .dot-bg {
    position: absolute;
    top: 0;
    left: -35px;
}

.support .left h5 {
    font-size: 15px;
  font-family: 'GorditaMedium';
    color: #2F2F2F;
}

.support .left .item p {
  font-family: 'GorditaMedium';
    font-size: 13px;
    color: #6A6A6A;
    line-height: 22px;
}

.support .left .item {
    padding: 0 0 20px 0;
}

.support .right{
    height: 100%;
    background-image: url('../../assets/images/whyZimplCrm/support.png');
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
}

@media only screen and (max-width:999px) {
    .support {
        margin: 40px 0 0 0;
    }
}

@media only screen and (max-width:767px) {
    .support {
        margin: 0 0 0 0;
    }

    .support .inner-subheading-box p {
        font-size: 14px;
        line-height: 24px;
    }
}
.compareCrm{
    margin: 100px 0 0 0;
}

.compareCrm .content-box{
    margin: 50px 0 0 0;
    background: linear-gradient(269.78deg, rgba(217, 217, 217, 0) 0.18%, rgba(76, 100, 253, 0.111) 98.97%);
    border-radius: 40px;
    padding: 50px;
}

.compareCrm .content-box .faq{
    margin: 0;
}


@media only screen and (max-width:767px) {
.compareCrm {
    margin: 40px 0 0 0;
}
}
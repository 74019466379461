.faq {
    margin: 100px 0;
}

.faq .heading-box {
    text-align: center;
}

.faq .heading-box h2 {
    font-size: 56px;
    color: var(--black-color);
    text-transform: uppercase;
    font-family: 'GorditaBold';
    padding: 0 0 40px 0;
}

.faq .accordion-button:not(.collapsed) {
    background-color: var(--white-color) !important;
    box-shadow: none !important;
    font-size: 18px;
    color: var(--black-color);
    font-family: 'GorditaBold';

}


.faq .accordion-item:first-of-type>.accordion-header .accordion-button {
    border: none !important;
    font-size: 18px;
    color: var(--black-color);
    font-family: 'GorditaMedium';
}

.faq .accordion-item {
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    border-bottom: 2px solid #D7DEF0;
}

.faq .accordion-button {
    font-size: 18px;
    color: var(--black-color);
    font-family: 'GorditaMedium';


}

.faq .accordion-body {
    font-size: 16px;
    color: #3c3c43e0;
}


.faq .accordion-button::after {
    transform: 90deg !important;
    background-image: url('../../assets/images/home/plus.png') !important;
}

.faq .accordion-button:not(.collapsed)::after {
    transform: 90deg !important;
    background-image: url('../../assets/images/home/minus.png') !important;
}

.faq .faq-box .faq-row{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}


.faq .faq-box .faq-row-item{
    width: 50%;
}




@media only screen and (max-width:999px) {

    .faq .accordion-button {
        font-size: 16px;
    }

    .faq .accordion-body {
        font-size: 14px;
    }

    .faq .heading-box h2 {
        font-size: 30px;
        padding: 0 0 20px 0;
    }

    .faq {
        margin: 50px 0;
    }
}

@media only screen and (max-width:767px) {
    .faq {
        margin: 50px 0;
    }

    .faq .heading-box h2 {
        font-size: 30px;
        padding: 0 0 20px 0;
    }

    .faq .accordion-item:first-of-type>.accordion-header .accordion-button {
        font-size: 16px;
    }

    .faq .accordion-body {
        font-size: 14px;
    }

    .faq .accordion-button {
        font-size: 16px;
    }


    .faq .accordion-button:not(.collapsed) {
        font-size: 16px;
    }
}
.leadManagementfeature {
    overflow: hidden;
    margin: 100px 0 0 0;
}

.leadManagementfeature .content-box {
    padding: 80px 200px 0 200px;
}

.leadManagementfeature .content-box .item {
    display: flex;
    justify-content: start;
    align-items: start;
}

.leadManagementfeature .content-box .item h5 {
    color: #2C2D2D;
    font-size: 18px;
    font-family: 'GorditaMedium';
    margin: 0;
    padding: 0 0 20px 0;
}

.leadManagementfeature .content-box .item p {
    font-size: 14px;
    color: #2C2D2D;
    font-weight: 400;
    margin: 0;
    line-height: 24px;
}


.leadManagementfeature .content-box .item .content {
    padding: 0 0 0 25px;
}


@media only screen and (max-width:1537px) {
    .leadManagementfeature .content-box {
        padding: 80px 100px 0 100px;
    }
}


@media screen and (min-width: 1000px) and (max-width: 1400px) {

    .leadManagementfeature .content-box {
        padding: 80px 10px 0 10px;
    }


    .benifitsOf-software {
        padding: 80px 10px 0 10px !important;
    }
}

@media only screen and (max-width:999px) {
    .leadManagementfeature .content-box {
        padding: 50px 0 0 0;
    }

}


@media only screen and (max-width:767px) {
    .leadManagementfeature {
        padding: 50px 0 0 0;
        margin: 0;
    }

    .leadManagementfeature .content-box {
        padding: 40px 10px 0 10px;
    }

    .leadManagementfeature .content-box .item h5 {
        font-size: 16px;
        padding: 0 0 6px 0;
    }

    .leadManagementfeature .content-box .item p {
        font-size: 12px;
        line-height: 18px;
    }

}
.footer {
    padding: 100px 0;
    border-top: 10px solid #EBEBEB;
}

.footer p {
    font-size: 16px;
    color: #6F6C90;
    padding: 30px 0 0 0;
}

.footer .box1 p {
    padding: 30px 0 0 0;
}

.footer h6 {
    color: #170F49;
    font-size: 20px;
font-family: 'GorditaBold';
}

.footer ul {
    list-style: none;
    padding: 15px 0 0 0;
}

.footer ul li {
    padding: 0 0 10px 0;
}

.footer ul li a {
    color: #6F6C90;
    text-decoration: none;
    font-size: 16px;;
}

.footer .box2 input {
    border-radius: 10px;
    border: 1px solid #D9DBE9;
    padding: 15px 10px;
    width: 85%;

}

.footer .box2 button {
    margin: 15px 0 0 0;
}

.footer .copy-right{
    margin: 30px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer .copy-right p{
    color: #6F6C90;
    font-size: 14px;
}

.footer .social-media{
    display: flex;
    align-items: center;
 
}

.footer .social-media a{
    margin: 0 0 0 10px;
    text-decoration: none;
}

.footer .box1 img{
    width: 200px;
}


@media only screen and (max-width:767px) {
    .footer {
        padding: 50px 10px;
    }

    .footer .box1 p {
        padding: 25px 0 0 0;
    }

    .footer ul li a {
        font-size: 16px;
    }

    .footer ul {
        padding: 10px 0 0 0;
    }

    .footer h6 {
        font-size: 18px;
    }

    .footer p {
        font-size: 16px;
        padding: 10px 0 0 0;
    }
}
.short-testimonial{
    margin: 200px 0 0 0;
    background-color: var(--secondary-color);
    height: 80px;
    width: 100%;
    position: relative;
}

.short-testimonial .box p{
    font-size: 16px;
    line-height: 24px;
    color: var(--black-color);
  font-family: 'GorditaMedium';
    margin: 0;
    text-align: start;
    padding: 16px 0 0 0;
}

.short-testimonial .box{
    width: 28%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 20px;

}

.short-testimonial .box .box-body{
    background-color: var(--white-color);
    border-radius: 20px 20px 0 0;
    padding: 15px;
    text-align: end;
    position: relative;
}

.short-testimonial .box .box-body::after{
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,0);
    background-color: var(--primary-color);
    width: 10%;
    height: 5px;
}



.short-testimonial .box .box-footer{
    background-color: var(--primary-color);
    border-radius: 0 0 20px 20px;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 12px 0 12px 15px;
}

.short-testimonial .box .box-footer h5{
    font-size: 12px;
    color: var(--white-color);
  font-family: 'GorditaBold';
    margin: 0;
}

.short-testimonial .box .box-footer p{
    color: #E2E2E2;
    font-weight: 400;
    font-size: 12px;
    margin: 0;
    padding: 0;
}

.short-testimonial .box .box-footer .text-box{
    padding: 0 0 0 20px;
}


@media only screen and (max-width:1537px) {
    .short-testimonial .box {
        width: 35%;
    }
}

@media only screen and (max-width:1024px) {
    .short-testimonial .box {
        width: 60%;
    }
}

@media only screen and (max-width:999px) {
    .short-testimonial .box {
        width: 60%;
    }
}


@media only screen and (max-width:767px) {
    .short-testimonial .box {
        width: 93%;
    }
    
    .short-testimonial .box p {
        font-size: 14px;
    }

    .short-testimonial {
        margin: 130px 0 0 0;
    }
}



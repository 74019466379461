.notfound{
    background-image: url('../../assets/images/404/bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 100px 0;
}

.notfound h1{
    color: #FFF3B4;
    font-family: 'GorditaBold';
    font-size: 150px;
    text-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2);

}

.notfound h2{
    font-family: 'GorditaBold';
    font-size: 30px;
    color: #000;
}

.notfound a{
    border: 2px solid #000;
    border-radius: 10px;
    color: #000;
    font-family: 'GorditaBold';
    padding: 10px;
    margin: 30px;
}

@media only screen and (max-width:767px) {
    .notfound h1{
        font-size: 80px;
    }

    .notfound h2{
        font-size: 16px;
    }
    
}
.pricing-banner {
    padding: 50px 0 0 0;
    background-image: url('../../assets/images/pricing/banner.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.pricing-banner h1 {
    color: #170F49;
    font-size: 50px;
    font-family: 'GorditaBold';
    font-weight: bold;
}



.pricing-banner .content-box {
    padding: 30px 0 0 0;
}

.pricing-banner .content-box .button-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pricing-banner .content-box .button-box h4 {
    margin: 0;
    color: #170F49;
    font-size: 18px;
}



.pricing-banner .content-box .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin: 0 10px;
}

.pricing-banner .content-box .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.pricing-banner .content-box .btn-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--primary-color);
    -webkit-transition: .4s;
    transition: .4s;
}

.pricing-banner .content-box .btn-slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.pricing-banner .content-box input:checked+.btn-slider {
    background-color: #2196F3;
}

.pricing-banner .content-box input:focus+.btn-slider {
    box-shadow: 0 0 1px #2196F3;
}

.pricing-banner .content-box input:checked+.btn-slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded btn-sliders */
.pricing-banner .content-box .btn-slider.round {
    border-radius: 34px;
}

.pricing-banner .package-box {
    width: 60%;
    padding: 30px 0px 70px 0;
    margin: auto;
}

.pricing-banner .content-box .btn-slider.round:before {
    border-radius: 50%;
}

.pricing-banner .package-box .row {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 20px;
}

.pricing-banner .package-box .card {
    border-radius: 20px;
    border: none;
    padding: 65px 40px;

}

.pricing-banner .package-box .card .tag-box {
    display: flex;
    align-items: center;
    margin: 0 0 25px 0;
}

.pricing-banner .package-box .card .tag-box img {
    margin: 0 10px 0 0;
}

.pricing-banner .package-box .card .tag-box p {
    color: #6F6C90;
    font-size: 18px;
    font-family: 'GorditaMedium';
    margin: 0;
}

.pricing-banner .package-box .card .tag-box h4 {
    font-size: 24px;
    color: #170F49;
    font-family: 'GorditaBold';
    margin: 0;
}

.pricing-banner .package-box .card h5 {
    color: #6F6C90;
    font-size: 18px;
    font-weight: 400;
    width: 75%;
    line-height: 26px;
}

.pricing-banner .package-box .card .price {
    color: #170F49;
    font-family: 'GorditaBold';
    font-size: 54px;
    font-weight: bold;
}

.pricing-banner .package-box .card span {
    color: #6F6C90;
    font-size: 20px;
    font-family: 'GorditaMedium';
}

.pricing-banner .package-box .card a {
    background-color: var(--primary-color);
    color: var(--white-color);
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: all 0.3s ease-in-out;
}

.pricing-banner .package-box .card button:hover {
    background-color: #3953ce;
}



.pricing-banner .package-box .right {
    background-color: #E0EAFF;
    border-radius: 0 20px 20px 0;
    display: flex;
    justify-content: center;
}

.pricing-banner .package-box .right h3 {
    color: #170F49;
    font-size: 18px;
    font-weight: bold;
    font-family: 'GorditaRegular';
    padding: 0 0 20px 0;

}

.pricing-banner .package-box .right .package-list {
    padding: 0;
    list-style: none;
}

.pricing-banner .package-box .right .package-list li {
    display: flex;
    justify-content: start;
    align-items: start;
    padding: 0 0 10px 0;
}

.pricing-banner .package-box .right .package-list li img {
    margin: 0 10px 0 0;
}

.pricing-banner .package-box .right .package-list li p {
    color: #170F49 !important;
    font-size: 16px;
    font-family: 'GorditaRegular';
    margin: 0;
    line-height: normal;
    padding: 0 0 14px 0;
}

.pricing-banner .feature-btn {
    text-align: center;
}


.pricing-banner .feature-btn button {
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    background-color: var(--white-color);
    font-size: 16px;
    font-weight: 400;
    border-radius: 6px;
    padding: 10px 15px;
}


@media only screen and (max-width:1537px) {
    .pricing-banner .package-box {
        width: 65%;
    }
}



@media only screen and (max-width:1024px) {

    .pricing-banner .package-box {
        width: 80%;
    }

    .pricing-banner .package-box .card .tag-box p {
        font-size: 16px;
    }

    .pricing-banner .package-box .card .tag-box h4 {
        font-size: 20px;
    }

    .pricing-banner .package-box .card h5 {
        font-size: 14px;
        width: 100%;
    }

    .pricing-banner .package-box .card .price {
        font-size: 40px;
    }

    .pricing-banner .package-box .card span {
        font-size: 16px;
    }

    .pricing-banner .package-box .card button {
        padding: 5px 13px;
    }

    .pricing-banner .package-box .card {
        padding: 20px 30px;
    }

    .pricing-banner .package-box .right h3 {
        font-size: 16px;
        padding: 0 0 10px 0;
    }

    .pricing-banner .package-box .right .package-list li p {
        font-size: 14px;
        padding: 0 0 14px 0;
    }

}


/* @media only screen and (min-width:1024px) and (max-width:1199px) {
    .feature-banner .bg-box .content-box {
        background-color: var(--white-color);
        position: absolute;
        left: 50%;
        bottom: -30%;
        transform: translate(-50%, 0);
        width: 90%;
        padding: 20px;
    }
} */


@media only screen and (max-width:999px) {
    .pricing-banner .package-box {
        width: 80%;
    }

    .pricing-banner .package-box .card .tag-box p {
        font-size: 16px;
    }

    .pricing-banner .package-box .card .tag-box h4 {
        font-size: 20px;
    }

    .pricing-banner .package-box .card h5 {
        font-size: 14px;
        width: 100%;
    }

    .pricing-banner .package-box .card .price {
        font-size: 40px;
    }

    .pricing-banner .package-box .card span {
        font-size: 16px;
    }

    .pricing-banner .package-box .card button {
        padding: 5px 13px;
    }

    .pricing-banner .package-box .card {
        padding: 20px 30px;
    }

    .pricing-banner .package-box .right h3 {
        font-size: 16px;
        padding: 0 0 10px 0;
    }

    .pricing-banner .package-box .right .package-list li p {
        font-size: 14px;
        padding: 0 0 14px 0;
    }
}

/* mobile screen */

@media only screen and (max-width:767px) {

    .pricing-banner {
        padding: 15px 0 0 0;
    }

    .pricing-banner .heading-box h1 {
        font-size: 30px;
    }

    .feature-banner .bg-box .content-box p {
        font-size: 14px !important;
    }

    .pricing-banner .heading-box p {
        color: #170F49;
        font-family: 'GorditaRegular';
        font-size: 14px;
        width: 90%;
        margin: auto;
        padding: 5px 0 0 0;
    }

    .pricing-banner .package-box {
        width: 100%;
        padding: 30px 0px 20px 0;
        margin: auto;

    }

    .pricing-banner .content-box .button-box h4 {
        font-size: 16px;
    }

    .pricing-banner .package-box .card {
        padding: 30px 30px;
    }

    .pricing-banner .package-box .card h5 {
        font-size: 16px;
        width: 100%;
    }

    .pricing-banner .package-box .card .tag-box p {
        font-size: 16px;
    }

    .pricing-banner .package-box .card .tag-box h4 {
        font-size: 20px;
    }

    .pricing-banner .package-box .card .price {
        font-size: 35px;
    }

    .pricing-banner .package-box .card span {
        font-size: 16px;
    }

    .pricing-banner .package-box .card button {
        padding: 8px 16px;
        font-size: 12px;
        border-radius: 6px;
    }

    .pricing-banner .package-box .right {
        border-radius: 0 0 20px 20px;
    }

    .pricing-banner .package-box .right h3 {
        font-size: 16px;
        padding: 0 0 10px 0;
    }

    .pricing-banner .package-box .right .package-list li p {
        font-size: 14px;
        padding: 0 0 8px 0;
    }

    .pricing-banner .package-box .right .package-list li img {
        max-width: 18px;
    }


    .pricing-banner .feature-btn button {
        padding: 8px 16px;
        font-size: 12px;
        border-radius: 6px;
    }



}





/* Add on plans */


.add-on-plans {
    padding: 100px 0 0 0;
    background-image: url('../../assets/images/pricing/bg1.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.add-on-plans .heading-box h6 {
    color: #170F49;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
}

.add-on-plans .content-box {
    padding: 100px 0 0 0;
}

.add-on-plans .content-box .card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    border: none;
    background-color: transparent;
}

.add-on-plans .content-box .card .card-body {
    width: 75%;
    background-color: var(--white-color);
    border-left: 1px solid #49434357;
    border-right: 1px solid #49434357;
}

.add-on-plans .content-box .card .card-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}



.add-on-plans .content-box .card .card-footer {
    width: 75%;
    background-color: var(--white-color);
    border-top: 0;
    border-bottom: 1px solid #49434357;
    border-left: 1px solid #49434357;
    border-right: 1px solid #49434357;
    padding: 0 30px 30px 30px;
    border-radius: 0 0px 25px 25px;
}


.add-on-plans .content-box .card-body h4 {
    color: #170F49;
    font-size: 24px;
    font-family: 'GorditaBold';
}

.add-on-plans .content-box .card-body p {
    color: #6F6C90;
    font-size: 18px;
    font-weight: 400;
    padding: 25px 0 5px 0;
}

.add-on-plans .content-box .card-body .price {
    font-size: 45px;
    font-family: 'GorditaBold';
    padding: 0 0 25px 0;
}



/* .add-on-plans .content-box .advace .price{
    color: #61D3AA;
} 

.add-on-plans .content-box .advace h4{
    color: #61D3AA;
}


.add-on-plans .content-box .advace .addOnbtn{
    background-color: #61D3AA;
} */



.add-on-plans .content-box .premium .price,
.add-on-plans .content-box .advace h4,
.add-on-plans .content-box .advace .price {
    color: #4C64FD;
}

.add-on-plans .content-box .premium h4,
.add-on-plans .content-box .enterprise .price,
.add-on-plans .content-box .enterprise h4 {
    color: #4C64FD;
}

.add-on-plans .content-box .premium .addOnbtn,
.add-on-plans .content-box .advace .addOnbtn,
.add-on-plans .content-box .enterprise .addOnbtn {
    background-color: #4C64FD;
}



/* .add-on-plans .content-box .enterprise .price{
    color: #F45BA2;
} 

.add-on-plans .content-box .enterprise h4{
    color: #F45BA2;
}


.add-on-plans .content-box .enterprise .addOnbtn{
    background-color: #F45BA2;
} */

.add-on-plans .content-box .card-body span {
    color: #6F6C90;
    font-family: 'GorditaBold';
    font-size: 20px;
}

.add-on-plans .content-box .card-body h3 {
    font-size: 18px;
    color: #170F49;
    font-weight: bold;
    margin: 0 0 20px 0;
    font-family: 'GorditaRegular';
}

.package-list {
    list-style: none;
    padding: 0;
}


.package-list li {
    display: flex;
    justify-content: start;
    align-items: start;
}

.package-list li p {
    padding: 0 !important;
}

.package-list li img {
    margin: 0 10px 0 0;
}

.addOnbtn {
    background-color: var(--primary-color);
    color: var(--white-color);
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.add-on-plans .content-box .card-body .package-list li {
    padding: 0 0 15px 0;
}

.add-on-plans .content-box .card-body .package-list p {
    color: #170F49 !important;
    font-size: 16px;
    font-family: 'GorditaRegular';
    margin: 0;
    line-height: normal;
}

@media screen and (min-width: 1400px) {
    .add-on-plans .content-box .card .card-footer {
        width: 85% !important;
    }

    .add-on-plans .content-box .card .card-body {
        width: 85% !important;
    }

}

@media screen and (min-width: 1537px) {
    .add-on-plans .content-box .card-body p {
        font-size: 16px;
    }


    .add-on-plans .content-box .card .card-footer {
        width: 75% !important;
    }

    .add-on-plans .content-box .card .card-body {
        width: 75% !important;
    }


}



@media screen and (min-width: 1000px) and (max-width: 1200px) {

    .add-on-plans .content-box .card-body {
        width: 40%;
    }

    .add-on-plans .content-box .box {
        text-align: center;
    }

    .add-on-plans .content-box .card-body h4 {
        font-size: 20px;
        text-align: start;
    }

    .add-on-plans .content-box .card-body p {
        font-size: 14px;
        padding: 0px 0 5px 0;
        text-align: start;
    }

    .add-on-plans .content-box .card-body .price {
        font-size: 40px;
        padding: 0 0 18px 0;
        text-align: start;
    }

    .addOnbtn {
        padding: 5px 13px;
    }

    .add-on-plans {
        padding: 60px 0 0 0;
    }

    .add-on-plans .content-box {
        padding: 60px 0 0 0;
    }

}


@media only screen and (max-width:1024px) {
    .add-on-plans .content-box .card .card-footer {
        width: 60% !important;
    }

    .add-on-plans .content-box .card .card-body {
        width: 60% !important;
    }
}


@media screen and (min-width: 991px) and (max-width: 1199px) {
    .add-on-plans .content-box .card .card-footer {
        width: 46% !important;
    }

    .add-on-plans .content-box .card .card-body {
        width: 46% !important;
    }
}



@media only screen and (max-width:999px) {


    .add-on-plans .content-box .card .card-footer {
        width: 60% !important;
    }

    .add-on-plans .content-box .card .card-body {
        width: 60% !important;
    }

    .add-on-plans .content-box .box {
        text-align: center;
    }

    .add-on-plans .content-box .card-body h4 {
        font-size: 20px;
        text-align: start;
    }

    .add-on-plans .content-box .card-body p {
        font-size: 14px;
        padding: 0px 0 5px 0;
        text-align: start;
    }

    .add-on-plans .content-box .card-body .price {
        font-size: 40px;
        padding: 0 0 18px 0;
        text-align: start;
    }

    .addOnbtn {
        padding: 5px 13px;
    }

    .add-on-plans {
        padding: 60px 0 0 0;
    }

    .add-on-plans .content-box {
        padding: 60px 0 0 0;
    }

}

@media only screen and (max-width:767px) {


    .add-on-plans .content-box .card .card-footer {
        width: 80% !important;
    }

    .add-on-plans .content-box .card .card-body {
        width: 80% !important;
    }

    .addOnbtn {
        padding: 8px 16px;
        font-size: 12px;
        border-radius: 6px;
    }


    .add-on-plans .content-box .card-body h4 {
        font-size: 20px;
    }

    .add-on-plans .content-box .card-body p {
        font-size: 14px;
        margin: 0;
    }

    .add-on-plans .content-box .card-body .price {
        font-size: 30px;
        padding: 0 0 10px 0;
    }

    .add-on-plans .content-box .card-body span {
        font-size: 16px;
    }

    .add-on-plans .content-box .card-body h3 {
        font-size: 16px;
    }

    .add-on-plans .content-box .box {
        padding: 0 20px;
    }

    .add-on-plans .content-box .card-body {
        width: 80%;
    }

    .add-on-plans {
        padding: 50px 0 0 0;
    }

    .add-on-plans .content-box {
        padding: 30px 0 0 0;
    }
}
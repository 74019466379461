.portfolio {
    background-color: #4C64FD20;
    margin: 100px 0 0 0;
    padding: 100px 0;
}

.portfolio .heading-box {
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 0 0 50px 0;
}

.portfolio .heading-box h3 {
    font-size: 40px;
    font-family: 'GorditaBold';
    color: var(--black-color);
    margin: 0;
}

.portfolio .heading-box p {
    font-size: 16px;
    color: var(--black-color);
    margin: 20px 0 0 0;
}

.portfolio .heading-box .left {
    width: 70%;
}

.portfolio .content-box .card {
    padding: 30px;
    border: none;
    background-color: var(--white-color);
    border-radius: 20px;
    height: 100%;
}

.portfolio .content-box .card .card-body {
    padding: 20px 0 0 0;
}

.portfolio .content-box .card .card-title {
    font-size: 26px;
    color: var(--black-color);
    font-family: 'GorditaMedium';
}

.portfolio .content-box .card .card-text {
    font-size: 16px;
    color: var(--black-color);
}

.portfolio .content-box .card .img-box {
    overflow: hidden;
    border-radius: 20px;
    position: relative;
    transition: all 0.3s ease-in-out;
}


.portfolio .content-box .card .img-box::after{
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    background: rgb(76,100,253);
    background: linear-gradient(320deg, rgba(76,100,253,1) 0%, rgba(45,60,151,0.52) 47%, rgba(45,60,151,0) 100%);
    transition: all 0.1s ease-in-out;
}


.portfolio .content-box .card .img-box:hover:after{
    right: -100%;
    bottom: -100%;
}

.portfolio .content-box .card .img-box .ovel-shape {
    position: absolute;
    right: -3%;
    bottom: -3%;
    z-index: 1;
    transition: all 0.3s ease-in-out;
}

.portfolio .content-box .card .img-box:hover .ovel-shape{
    right: -100%;
    bottom: -100%;
}

.portfolio .content-box .card .img-box .disc-shape1 {
    position: absolute;
    right: -9%;
    bottom: -8%;
    z-index: 1;
    transition: all 0.5s ease-in-out;
}

.portfolio .content-box .card .img-box:hover .disc-shape1 {
    right: -100%;
    bottom: -100%;
}

.portfolio .content-box .card .img-box .disc-shape2 {
    position: absolute;
    right: -7%;
    bottom: -13%;
     z-index: 1;
    transition: all 0.7s ease-in-out;
}

.portfolio .content-box .card .img-box:hover .disc-shape2 {
    right: -100%;
    bottom: -100%;
}

.portfolio .swiper-slide  {
    height: auto;
}



/* .portfolio .content-box .card .img-box img:hover{
    transform: skew(5deg) scale(1.09);
} */



@media only screen and (max-width:1537px){
    .portfolio .content-box .card .img-box .ovel-shape {
        right: -6%;
        bottom: -12%;
    }

    .portfolio .content-box .card .img-box .disc-shape1 {
        right: -14%;
        bottom: -22%;
    }

    .portfolio .content-box .card .img-box .disc-shape2 {
        position: absolute;
        right: -10%;
        bottom: -30%;
        z-index: 1;
        transition: all 0.7s ease-in-out;
    }
}

@media only screen and (max-width:1024px){
.portfolio .swiper-wrapper {
    height: 41vh;
}
}

@media only screen and (max-width:999px) {

    .portfolio .swiper-wrapper {
        height: 64vh;
    }

    .portfolio .heading-box {
        flex-wrap: wrap;
    }

    .portfolio .heading-box .left {
        width: 100%;
        padding: 0 0 30px 0;
    }

    .portfolio .content-box .card .card-title {
        font-size: 22px;
        color: var(--black-color);
        font-family: 'GorditaMedium';
    }


    .portfolio .content-box .card .img-box .ovel-shape {
        right: -14%;
        bottom: -30%;
    }

    .portfolio .content-box .card .img-box .disc-shape1 {
        right: -31%;
        bottom: -55%;
    }

    .portfolio .content-box .card .img-box .disc-shape2 {
        right: -28%;
        bottom: -66%;
    }


}

@media only screen and (max-width:767px) {
    .portfolio .heading-box {
        padding: 0 0 50px 0;
        flex-wrap: wrap;
        gap: 25px;
    }
    .portfolio .swiper-wrapper {
        height: 55vh;
    }
    .portfolio {
        background-color: #4C64FD20;
        margin: 50px 10px 0 10px;
        padding: 50px 0;
    }

    .portfolio .heading-box .left {
        width: 100%;
    }

    .portfolio .heading-box p {
        font-size: 14px;
        color: var(--black-color);
        margin: 10px 0 0 0;
    }

    .portfolio .content-box .card .card-title {
        font-size: 16px;
        color: var(--black-color);
        font-family: 'GorditaMedium';
    }

    .portfolio .content-box .card .card-text {
        font-size: 12px;
        color: var(--black-color);
    }
}




.contact {
    background-color: var(--secondary-color);
    padding: 100px 0;
}

.contact-box {
    max-width: 1440px;
    margin: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #0d0a2f;
    border-radius: 20px;
}

.contact-box .bg-contact {
    border-radius: 20px 0 0 20px;
}


.contact-box .right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 62px 0 0px;
}

.contact-box .right h2 {
    color: var(--white-color);
  font-family: 'GorditaMedium';
    font-size: 48px;
}

.contact-box .right p {
    color: #A2A9B0;
    font-size: 16px;
    font-weight: normal;
    padding: 10px 0 0px 0;
}

.contact-box .right form label {
    display: block;
    color: #F8F9FA;
  font-family: 'GorditaMedium';
    font-size: 16px;
    padding: 35px 0 10px 0;
}

.contact-box .right form input {
    background-color: #3B4857;
    padding: 15px 15px;
    border: 1px solid hsla(0, 0%, 100%, 0.199);
    border-radius: 10px;
    width: 100%;
    color: var(--white-color);
}

.contact-box .right form textarea {
    background-color: #3B4857;
    padding: 15px 15px;
    border: 1px solid hsla(0, 0%, 100%, 0.199);
    border-radius: 10px;
    width: 100%;
    height: 150px;
    color: var(--white-color);
}

.contact-box .right form textarea::placeholder {
    color: #CFD3D7;
    font-size: 16px;
}

.contact-box .right form input::placeholder {
    color: #CFD3D7;
    font-size: 16px;
}


.contact-box .right form .mobile-box {
    display: flex;
    align-items: center;
}

.contact-box .right form button {
    margin: 10px 0 0 0;
    background-color: var(--primary-color);
    color: var(--white-color);
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: fit-content;
}

.intl-tel-input {
    width: 100%;
}

@media only screen and (max-width:1024) {
    .contact {
        background-color: var(--secondary-color);
        padding: 100px 40px;
    }
}

@media only screen and (max-width:999px) {
    .contact {
        background-color: var(--secondary-color);
        padding: 100px 40px;
    }

    .contact-box .right {
        padding: 50px;
    }

    .contact-box .bg-contact {
        border-radius: 20px;
        width: 100%;
    }


    .contact-box .right h2 {
        font-size: 30px;
    }

    .contact-box .right p {
        font-size: 14px;
        padding: 10px 0 0px 0;
        margin: 0;
    }

    .contact-box .right form label {
        font-size: 14px;
        padding: 14px 0 10px 0;
    }

    .contact-box .right form input {
        background-color: #3B4857;
        padding: 10px;
    }
}


@media only screen and (max-width:767px) {
    .contact {
        background-color: var(--secondary-color);
        padding: 20px 14px;
    }


    .contact-box .bg-contact {
        border-radius: 20px;
    }

    .contact-box .right {
        padding: 30px;
    }

    .contact-box .right h2 {
        font-size: 30px;
    }

    .contact-box .right p {
        padding: 0;
    }

    .contact-box .right form input {
        padding: 10px;
    }

    .contact-box .right form label {
        font-size: 14px;
        padding: 20px 0 10px 0;
    }

}

.form-submit-response{
    color: red;
font-family: 'GorditaBold';
    font-size: 20px;
    text-align: center;
}
.blogs {
    margin: 70px auto auto auto;
}

.blog-slider {
    margin: 500px auto auto auto;
}

.blogs .heading-box {
    text-align: center;
}

.blogs .heading-box h2 {
    font-size: 56px;
    color: var(--black-color);
    font-family: 'GorditaBold';
    padding: 0 0 40px 0;
}

.blogs .content-box .left,
.blogs .content-box .right {
    position: relative;

}

.blogs .content-box .left .img-box,
.blogs .content-box .right .img-box {
    overflow: hidden;
    border-radius: 24px;
}

.blogs .content-box .left .img-box img,
.blogs .content-box .right .img-box img {
    border-radius: 24px;
    transition: transform 0.3s ease, border-radius 0.3s ease;

    object-fit: cover;
    height: 100%;
    width: 100%;
    
}

.blogs .content-box .left img:hover,
.blogs .content-box .right img:hover {
    transform: scale(1.3) rotate(10deg);
    border-radius: 24px;
}

.blogs .content-box .card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: none;
    position: absolute;
    left: 50%;
    bottom: -65%;
    transform: translate(-50%, 0%);
    padding: 40px;
    width: 38rem;
}

.blogs .content-box h4 {
    font-size: 25px;
    font-family: 'GorditaBold';
    color: #181A2A;
}

.blogs .content-box .card h3 {
    font-size: 36px;
    font-family: 'GorditaMedium';
    color: var(--black-color);
}

.blogs .content-box .card p {
    color: #000000;
    font-size: 16px;
    line-height: 30px;
}

.blogs .content-box .card a {
    background-color: var(--primary-color);
    color: var(--white-color);
    border: none;
    padding: 7px 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 14px;
    width: max-content;
}

.blogs .content-box .card .card-footer {
    display: flex;
    align-items: center;
    background-color: var(--white-color);
    border: none;
    padding: 0;
    margin: 20px 0 0 0;
}

.blogs .content-box .card .card-footer img {
    margin: 0 10px 0 0;
}

.blogs .content-box .card .card-footer h6 {
    color: #97989F;
    font-size: 16px;
    padding: 0 10px 0 0;
}

.blogs .content-box .card .card-footer p {
    color: #97989F;
    font-size: 16px;
}

.blogs .content-box .card .card-footer p,
.blogs .content-box .card .card-footer h6 {
    margin: 0;
}

.blog-slider .card {
    padding: 16px;
    border: 1px solid #E8E8EA;
    /* width: 20rem; */
}

.blog-slider .card:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.blog-slider .card:hover .card-img-top {
    transform: scale(1.2);
    border-radius: 6px;
}

.blog-slider .card:hover .card-title {
    color: var(--primary-color);
}

.blog-slider .card .img-box {
    overflow: hidden;
    border-radius: 6px;
}

.blog-slider .card .card-img-top {
    transition: transform 0.3s ease, border-radius 0.3s ease;
    border-radius: 6px;
}

/* .blog-slider .card .card-img-top:hover{
    transform: scale(1.2);
    border-radius: 6px;
} */

.blog-slider .card-title {
    font-size: 20px;
    color: #181A2A;
    line-height: 28px;
    font-family: 'GorditaMedium';
}

.blog-slider .card-body {
    padding-left: 0;
    padding-right: 0;
}

.blog-slider .card-footer {
    background-color: var(--white-color);
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
}

.blog-slider .card-footer img {
    margin: 0 10px 0 0;
}

.blog-slider .card-footer h6 {
    font-family: 'GorditaMedium';
    padding: 0 10px 0 0;
}

.blog-slider .card-footer h6,
.blog-slider .card-footer p {
    margin: 0;
    font-size: 14px;
    color: #97989F;
}

@media only screen and (max-width:1537px) {
    .blog-slider .card-footer h6,
    .blog-slider .card-footer p {
        margin: 0;
        font-size: 12px;
        color: #97989F;
    }
}

.blog-slider .view-all-box {
    text-align: center;
    margin: 50px 0 0 0;
}

.blog-slider .view-all-box button {
    background-color: var(--white-color);
    border-radius: 6px;
    padding: 10px;
    font-size: 16px;
    color: #696A75;
    border: 1px solid #000000030;
    font-family: 'GorditaMedium';
}

@media only screen and (max-width:1024px) {
    .blogs .content-box .card {
        left: 50%;
        bottom: -184%;
        transform: translate(-50%, 0%);
        padding: 40px;
        width: 27rem;
    }

    .blog-slider {
        margin: 586px auto auto auto;
    }

    .blog-slider .card {
        width: auto;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        font-size: 14px;
    }
}

@media only screen and (max-width:999px) {
    .blogs .heading-box h2 {
        font-size: 30px;
        padding: 0 0 10px 0;
    }

    .blogs .content-box .card {
        left: 50%;
        bottom: -191%;
        transform: translate(-50%, 0%);
        padding: 25px;
        width: 18rem;
    }

    .blogs .content-box .card h3 {
        font-size: 18px;
    }

    .blogs .content-box .card p {
        font-size: 14px;
        line-height: 22px;
    }

    .blogs .content-box .card .card-footer h6 {
        font-size: 12px;
    }

    .blogs .content-box .card .card-footer p {
        font-size: 12px;
    }

    .blogs .content-box h4 {
        font-size: 20px;
    }

    .blog-slider .card-title {
        font-size: 20px;
        color: #181A2A;
        line-height: 25px;
        font-family: 'GorditaMedium';
    }

    .blog-slider .card-footer h6,
    .blog-slider .card-footer p {
        font-size: 12px;
    }

    .blog-slider .view-all-box button {
        padding: 8px;
        font-size: 14px;
    }

    .blogs {
        margin: 50px auto auto auto;
    }

    .blog-slider {
        margin: 480px auto auto auto;
    }

}

/* mobile screen */

@media only screen and (max-width:767px) {
    .blogs .content-box {
        overflow: hidden;
        padding: 0px 0px 30px 0;
    }

    .blogs .content-box .card {
        left: 50%;
        bottom: -289px;
        transform: translate(-50%, 0%);
        max-width: 20rem;
        padding: 20px;
    }

    .blogs .content-box .card h3 {
        font-size: 18px;
    }

    .blogs .content-box .card p {
        font-size: 14px;
        line-height: normal;
    }

    .blogs .content-box .card a {
        padding: 7px 10px;
        font-size: 12px;
    }

    .blogs .content-box .card .card-footer h6 {
        font-size: 12px;
    }

    .blogs .content-box .card .card-footer p {
        font-size: 12px;
    }

    .blogs .content-box .left {
        margin: 0 0 270px 0;

    }

    .blogs .content-box .right {
        margin: 0 0 270px 0;
    }

    .blog-slider {
        margin: 50px auto auto auto;
    }

    .blog-slider .card {
        width: auto;
    }

    .blog-slider .card-title {
        font-size: 18px;
        line-height: 20px;
    }
}

@media only screen and (max-width:425px) {
    .blogs .content-box .card {
        max-width: 22rem;
    }
}


@media only screen and (max-width:320px) {
    .blogs .content-box .card {
        max-width: 18rem;
    }
}
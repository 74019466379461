.officeLocation{
    margin: 100px 0 0 0;
}

.officeLocation .blue-bg-box{
    padding: 100px 0 0 0;
    background: linear-gradient(180deg, #4C64FD 60%, #ffffff 60%);
}

.officeLocation .left{
    position: relative;
}

.officeLocation .blue-bg-box .content-box{
    padding: 50px 0 0 0;
}

.officeLocation .location-box .country-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.officeLocation .left,.officeLocation .right{
    position: relative;
}

.officeLocation .location-box{
    background-color: var( --white-color);
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%,0);
    border-radius: 12px;
    padding: 25px;
    width: 80%;
    height: 40%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.officeLocation .location-box address{
    width: 76%;
    font-size: 16px;
    color: #000000;
}


.officeLocation  .email,.officeLocation  .phone{
    max-width: 30px;
    margin: 0 10px 0 0;
}


.officeLocation .location-box a{
    text-decoration: none;
    color: var(--black-color);
    font: 14px;
}


.officeLocation .location-box .location-box{
    position: relative;
}

.officeLocation .connect-footer{
    position: absolute;
    bottom: 10%;

}

@media only screen and (max-width:1536px) {
    .officeLocation .location-box {
        height: 50%;
    }
}

@media only screen and (max-width:1399px) {
    .officeLocation .location-box {
        height: 70%;
    }
}

@media only screen and (max-width:1199px) {
    .officeLocation .location-box {
        height: 94%;
    }
}


@media only screen and (max-width:991px) {
    .officeLocation .location-box {
        height: 50%;
    }
}

@media only screen and (max-width:767px) {
    .officeLocation .location-box {
        height: 92%;
    }
}


@media only screen and (max-width:767px) {
.officeLocation {
    margin: 50px 0 0 0;
}


.officeLocation .blue-bg-box {
    padding: 50px 0 50px 0;
    background: linear-gradient(180deg, #4C64FD 60%, #4C64FD 60%);
}

.officeLocation .location-box address {
    width: 100%;
    font-size: 14px;
}
}
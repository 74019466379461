.historyOfCrm {
    margin: 100px 0 0 0;
}

.historyOfCrm h1{
    font-size: 36px;
    color: var(--black-color);
    font-weight: 600;
    padding: 0 0 20px 0;
    font-family: 'GorditaMedium';
}



.historyOfCrm .right .heading-para {
    color: #000000;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
}

.historyOfCrm .right ul {
    list-style: none;
}

.historyOfCrm .right li {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0 0 20px 0;
}

.historyOfCrm .right ul p {
    margin: 0;
    line-height: normal;
    padding: 0 0 0 15px;
    color: #000000;
    font-size: 16px;
}

.historyOfCrm .inner-heading-box {
    padding: 0 0 50px 0;
}


.historyOfCrm .left{
    height: 100%;
    background-image: url('../../assets/images/about/ourHistory.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
}



@media only screen and (max-width:767px) {
    .historyOfCrm {
        margin: 50px 0 0 0;
    }
}
.weHelp{
    margin: 100px 0 0 0;
}

.weHelp .content-box{
    margin: 50px 0 0 0;
}

.weHelp-btn{
    background-color: var(--primary-color);
    color: var(--white-color);
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.weHelp .card{
    border: none;
    border-radius: 40px;
    background-color: #4c63fd20;
    text-align: center;
    padding: 50px;
}

.weHelp .card .card-body{
    padding: 30px 0 0 0;
}
.weHelp .card h5{
    font-size: 24px;
    font-family: 'GorditaMedium';
    color: #2C2D2D;
}

.weHelp .card p{
    font-size: 14px;
    color: #2C2D2D;
}

.weHelp .bottom-content{
    margin: 30px 0 0 0;
}

.weHelp .bottom-content h5{
    font-size: 24px;
    font-family: 'GorditaMedium';
    color: #170F49;
    margin: 0;
}

.weHelp .bottom-content p{
    color: #626262;
    font-size: 18px;
    margin: 20px 0 30px 0;
}

@media only screen and (max-width:767px) {
.weHelp {
    margin: 50px 10px 0 10px;
}
.weHelp .card {
    padding: 25px;
}

.weHelp .card h5 {
    font-size: 20px;
}

.weHelp .bottom-content h5 {
    font-size: 22px;
}

.weHelp .bottom-content p {
    font-size: 14px;
    margin: 10px 0 20px 0;
}
}